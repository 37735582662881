import React, { PureComponent } from "react";
import {TweenLite} from "gsap/TweenLite";
import {Close} from "../../partials/Icons";


export default class Modal extends PureComponent {
  render() {
    return (
      <div className="reviews-modal-wrapper j-reviews-modal">
        <button className="btn reviews-close-btn" onClick={this.props.onClose}><Close/></button>

        <div className="reviews-modal-content">
          { this.props.children }
        </div>
      </div>
    )
  }
}

export const Image = (props) => {
  const image = props.img ? props.img : '';

  return(
    <div className="reviews-modal-item">
      <svg className="svg-defs">
        <defs>
          <clipPath id="clipping">
            <rect id="rectangle" className="svg-size" x="0" y="0" />
          </clipPath>
        </defs>
      </svg>
      <svg className="svg-size" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
        <image className="svg-image svg-size" xlinkHref={ image } x="0" y="0"/>
      </svg>
    </div>
  );
}

export const List = (props) => {
  const createList = () => {
    const activeIndex = props.activeItem;

    return props.items.map((item, index) => {
      const activeClass = index === activeIndex ? 'is-active' : '';

      return <li className={`reviews-links-list__item ${activeClass}`} key={index} onClick={rollupImage.bind(this, index)}>
        <span className="reviews-links-list__span j-review-link">
          {item.client}
        </span>
      </li>
    });
  }

  const rollupImage = (index) => {
    TweenLite.fromTo(
      "#rectangle",
      0.6,
      {
        scaleX: 1
      },
      {
        ease: Power3.easeInOut,
        scaleX: 0,
        transformOrigin:"100% 0",
        onComplete: () => {
          props.activeProp(index);
          expandImage();
        }
      }
    );
  }

  const expandImage = () => {
    TweenLite.fromTo(
      "#rectangle",
      0.6,
      {
        scaleX: 0
      },
      {
        scaleX: 1,
        ease: Power3.easeInOut,
        transformOrigin:"100% 0"
      }
    );
  }

  return(
    <div className="reviews-modal-links">
      <ul className="reviews-links-list">
        {createList()}
      </ul>
    </div>
  );
}
