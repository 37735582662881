import React from "react";
import { LeftControl, RightControl } from "../../partials/Icons";
import { IProductVideo } from "./types";

type ProductCarouselNavigationProps = {
  slides: IProductVideo[];
  active: number;
  updateActive: (v: number) => void;
};

export const ProductCarouselNavigation = ({
  slides,
  active,
  updateActive,
}: ProductCarouselNavigationProps) => {
  const handleNavigationClick = (index: number) => {
    if (index < 0 || index > slides.length - 1) return;

    updateActive(index);
  };

  return (
    <div className="product-carousel-controls">
      <button
        className="product-carousel-btn is-prev"
        onClick={() => handleNavigationClick(active - 1)}
        disabled={active === 0}
      >
        <LeftControl />
      </button>
      <div className="product-carousel-counter">
        <span className="is-active">{active + 1}/</span>
        <span>{slides.length}</span>
      </div>
      <button
        className="product-carousel-btn is-next"
        onClick={() => handleNavigationClick(active + 1)}
        disabled={active === slides.length - 1}
      >
        <RightControl />
      </button>
    </div>
  );
};
