import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import Footer from "../../partials/footer/Footer";
import Navbar from "../../partials/navbar/Navbar";
import api from "../../../api";
import { getPostData } from "../../../api/posts";

const getPosts = () => api.get("/posts");

interface Props extends WithTranslation {
  t: any;
}

type State = {
  info: {
    date: string;
    description: string;
    image: string;
    title: string;
  };
};

class ContentSingleNews extends Component<Props, State> {
  state = {
    info: {
      date: "",
      description: "",
      image: "",
      title: "",
    },
  };

  componentDidMount() {
    this.getInfo();
  }

  async getInfo() {
    const pathname = window.location.pathname.split("/");
    const lastSegment = pathname.pop();
    const data = await getPostData(lastSegment);

    this.setState({ info: data.data[0].attributes });
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Navbar />
        <main className="main__theme-blue">
          <section className="news-section">
            <ul className="breadcrumbs is-absolute">
              <li>
                <a href="/">{t("Main page")}</a>
              </li>
              <li>
                <a href="/news">{t("News")}</a>
              </li>
              <li>{this.state.info.title || ""}</li>
            </ul>

            <section className="news-navigation"></section>

            <article className="single-news-container section_theme_padding-top">
              <h1 className="single-news-title j-text-appear">
                <span className="text-appear-wrap">
                  <span className="text-appear">
                    {this.state.info.title || ""}
                  </span>
                </span>
              </h1>
              <time className="single-news-date">
                {this.state.info.date || ""}
              </time>
              <div className="media-container is-shown">
                <div className="media-container__appear is-white"></div>
                <div className="media-container__appear is-color"></div>
                <img
                  src={this.state.info.image}
                  alt={this.state.info.title || ""}
                />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.info.description,
                }}
              />
            </article>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(ContentSingleNews);
