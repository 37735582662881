import React from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

import api from "../../../api";
import {
  ChevronDown,
  ChevronInCircle,
  ChevronUp,
  Close,
  Configurator,
  Logo,
} from "../../partials/Icons";
import { getCart } from "../../../redux/reselect";
import {
  getSerial,
} from "../../partials/ConfiguratorParts";
import { closeResultModal, openConfigurator } from "./ConfiguratorAnimations";
import {
  changeQuantity,
  removeCartItem,
  clearCart,
} from "../../../redux/actions/cart";

const Cart = ({ cart, onChangeQuantity, onRemoveCartItem, onClearCart }) => {
  const formik = useFormik({
    initialValues: {
      user_org: "",
      user_name: "",
      phone: "",
      email: "",
      message: "",
      items: [],
    },
    onSubmit: (values) => {
      const list = cart.map((item) => {
        const serial = getSerial(item);
        const options = item.resultItemsList.map(({ attributes }) => {
          const { code, description, hint, name, value, slug } = attributes;

          return {
            code,
            description,
            hint,
            name,
            value,
            slug,
          };
        });

        return {
          serial,
          amount: item.amount,
          options,
        };
      });

      api.post("product_requests", {
        data: {
          type: "product_requests",
          attributes: {
            ...values,
            items: list,
          },
        },
      });

      closeResultModal("#cartModal");
      onClearCart();
    },
  });
  const { t } = useTranslation();

  return (
    <div id="cartModal" className="configurator-result">
      <div className="configurator-header navbar-theme-colored">
        <div className="configurator-header-wrap hide-elements">
          <Link to="/">
            <Logo />
          </Link>
          <button
            className="btn"
            onClick={() => closeResultModal("#cartModal")}
          >
            <Close />
          </button>
        </div>
      </div>
      <div className="configurator-left-column"></div>
      <div className="configurator-right-column">
        <h3 className="configurator-title">
          <span className="configurator-title__span">
            <Configurator />
            Заявка на заказ
          </span>
        </h3>
        <div className="configurator-result__cart">
          {cart.map((item) => {
            console.log(item);
            return (
              <OrderInCart
                data={item}
                onClickHandle={onChangeQuantity}
                onRemoveHandle={onRemoveCartItem}
                key={item.id}
              />
            );
          })}
          <button
            className="configurator-order__add-plate"
            onClick={() => {
              openConfigurator();
              closeResultModal("#cartModal");
            }}
          >
            +
          </button>

          <form
            action=""
            onSubmit={formik.handleSubmit}
            id="product-form"
            className="product-form"
          >
            <div className="form-group">
              <input
                id="user_org"
                name="user_org"
                value={formik.values.user_org}
                onChange={formik.handleChange}
                type="text"
                className="input-default"
                placeholder={t("Org")}
              />
              <span className="form-require-text">{t("Optional")}</span>
            </div>
            <div className="form-group">
              <input
                id="user_name"
                name="user_name"
                value={formik.values.user_name}
                onChange={formik.handleChange}
                type="text"
                className="input-default"
                placeholder={t("Name")}
                required
              />
            </div>
            <div className="form-group">
              <input
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
                className="input-default"
                placeholder="E-mail"
                required
              />
            </div>
            <div className="form-group">
              <InputMask
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                mask="+7(999) 999-99-99"
                maskChar=" "
                className="input-default"
                placeholder={`${t("Pho")}: +7(___)___-__-__`}
                required
              />
            </div>
            <div className="form-group">
              <textarea
                id="message"
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                className="input-default text-area-default"
                placeholder={t("Message")}
                rows={1}
                required
              />
              <span className="form-require-text">{t("Optional")}</span>
            </div>
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="agreement"
                className="input-checkbox"
                name="agreement"
              />
              <span className="input-checkbox-mark" />
              <label htmlFor="agreement" className="checkbox-label">
                {t("I consent")} <a href="#">{t("Consent")}</a>{" "}
                {t("The processing")}
              </label>
            </div>
          </form>
          <footer className="configurator-footer">
            <button
              className="btn btn__theme__request"
              type="submit"
              form="product-form"
            >
              <ChevronInCircle />
              Отправить
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

const OrderInCart = ({ data, onClickHandle, onRemoveHandle }) => (
  <div className="configurator-order">
    <div className="configurator-order__head">
      <div className="configurator-result__title">
        {getSerial(data)}
      </div>
      <div className="configurator-order__controls">
        <button
          className="configurator-order__btn"
          onClick={() => onRemoveHandle(data)}
        >
          Удалить
        </button>
      </div>
    </div>
    <div className="configurator-order__amount">
      <span>Кол-во: {data.amount}</span>
      <div className="configurator-order__amount-controls">
        <button
          className="configurator-order__amount-btn is-add"
          onClick={() => onClickHandle(data, data.amount + 1)}
        >
          <ChevronUp />
        </button>
        <button
          className="configurator-order__amount-btn is-remove"
          onClick={() => onClickHandle(data, data.amount - 1)}
        >
          <ChevronDown />
        </button>
      </div>
    </div>
  </div>
);

const mapStateToProps = (store) => ({
  cart: getCart(store),
});

const mapDispatchToProps = (dispatch) => ({
  onChangeQuantity: (item, amount) => {
    dispatch(changeQuantity(item, amount));
  },
  onRemoveCartItem: (item) => {
    dispatch(removeCartItem(item));
  },
  onClearCart: () => {
    dispatch(clearCart());
  },
});

export const ModalCart = connect(mapStateToProps, mapDispatchToProps)(Cart);
