export const heroLinkConfig = {
  closed: [
    {
      href: "/products/raspredelitelnye-ustroystva",
      x: 21.3,
      y: 53.5,
      ru: "KCO Онега 10 кВ",
      en: "MV panels Onega 12 kV"
    },
    {

      href: "/products/raspredelitelnye-ustroystva",
      ru: "КРУ 10 кВ с напольным ВЭ",
      en: "Metal-clad switchgear Volga 12 kV",
      x: 43,
      y: 61
    },
    {
      href: "products/raspredelitelnye-ustroystva",
      ru: "КРУ 20 кВ",
      en: "Metal-clad switchgear Volga 24 kV",
      x: 60.5,
      y: 27.5
    },
    {
      href: "/products/raspredelitelnye-ustroystva",
      ru: "КРУ 35 кВ",
      en: "Metal-clad switchgear Volga 40 kV",
      x: 76.3,
      y: 58,
      right: true
    },
  ],

  opened: [
    {
      href: "/products/kommutatsionnye-apparaty",
      ru: "Вакуумный выключатель VM12",
      en: "Vacuum circuit-breakers VM 12kV",
      x: 14.1,
      y: 51.5,
      vertical: true
    },
    {
      href: "/products/kommutatsionnye-apparaty",
      ru: "Элегазовый выключатель SL12",
      en: "Three-position SF6-insulated MV switching devices SL 12 kV",
      x: 25.5,
      y: 69
    },
    {
      href: "/products/kommutatsionnye-apparaty",
      ru: "Вакуумный выключатель VFl2",
      en: "MV vacuum circuit-breakers 12 kV",
      x: 41.4,
      y: 57.5
    },
    {
      href: "/products/kommutatsionnye-apparaty",
      ru: "Вакуумный выключатель VF24",
      en: "MV vacuum circuit-breakers 24 kV",
      x: 57.5,
      y: 52.5
    },
    {
      href: "/products/kommutatsionnye-apparaty",
      ru: "Вакуумный выключатель VF40",
      en: "MV vacuum circuit-breakers 40 kV",
      x: 70.5,
      y: 70,
      right: true
    }
  ]
}
