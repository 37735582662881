import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Container from "./partials/Container";
import Search from "./partials/search/Search";
import Loader from "./partials/Loader";
import { Provider } from 'react-redux';

import './i18n';
import { store } from '../store/configureStore';

const App = ({
  seo_tags
}) => (
  <Provider store={store}>
    <Router>
      <Container seo_tags={seo_tags}/>
      <Search />
      <Loader />
    </Router>
  </Provider>
);

export default App;
