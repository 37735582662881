import api from "./index";

export const getConfiguratorData = async (id) => {
  try {
    const request = await api.get(`configurators/${id}?include=
configurator_steps.configurator_options.dependent_options,
configurator_steps.configurator_substeps.configurator_options,
configurator_steps.configurator_options.links.dependent_options,
configurator_steps.configurator_options.except_options`);

    return request.data;
  } catch (e) {
    console.error(e);
  }
};

export const getStepData = async (stepIndex, groups, steps) => {
  const currentGroup = groups[`group${stepIndex}`];
  const currentStep = steps[stepIndex];

  let query = `configurator_steps/${currentStep.id}/confirm?`;

  if (Array.isArray(currentGroup)) {
    currentGroup.map((step, i) => {
      query += `options[]=${step.id}&`;
    });
  } else {
    query += `options[]=${currentGroup.id}`;
  }

  try {
    const res = await api.get(query);
    return res;
  } catch (e) {
    console.log("Get Configurator Step Data Error::", e);
  }
};

export const submitResult = async (code, modIds) => {
  try {
    const response = await api.get('configurator_models/find_by_code?' + new URLSearchParams({
      code: code, mod_ids: modIds
    }));
    return response.data.data.attributes;
  } catch (e) {
    console.error(e);
  }
}

export const optionCode = async (optionId, dependentItemIds) => {
  try {
    const response = await api.get('configurator_options_notations/find_by_option_id?' + new URLSearchParams({
      option_id: optionId, dependent_item_ids: dependentItemIds
    }));

    return response.data.data?.attributes;
  } catch (e) {
    console.error(e);
  }
}

export default {
  submitResult
}
