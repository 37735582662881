import {TweenMax} from "gsap";

export default class SlideAnimation {
  constructor(el, settings) {
    this.DOM = {el: el};

    this.settings = {
      detailsEl: null
    }

    Object.assign(this.settings, settings);
    this.DOM.wrap = this.DOM.el.querySelector('.product-carousel-slide__wrap');
    this.DOM.img = this.DOM.wrap.querySelector('.product-carousel-slide__img');
    this.config = {
      animation: {
        duration: 1.2,
        ease: Expo.easeInOut
      }
    };
  }

  hide(direction) {
    return this.toggle('hide', direction);
  }

  show(direction) {
    this.DOM.el.style.zIndex = 3;
    return this.toggle('show', direction);
  }

  toggle(action, direction) {
    return new Promise((resolve, reject) => {
      if ( action === 'show' ) {
        TweenMax.to(this.DOM.wrap, this.config.animation.duration, {
          ease: this.config.animation.ease,
          startAt: {x: direction === 'right' ? '100%' : '-100%'},
          x: '0%'
        });
      }

      TweenMax.to(this.DOM.img, this.config.animation.duration, {
        ease: this.config.animation.ease,
        startAt: action === 'hide' ? {} : {x: direction === 'right' ? '-100%' : '100%', scale: 1.1},
        x: '0%',
        scale: action === 'hide' ? 1.1 : 1,
        onStart: () => {
          this.DOM.img.style.transformOrigin = action === 'hide' ?
            direction === 'right' ? '100% 50%' : '0% 50%':
            direction === 'right' ? '0% 50%' : '100% 50%';
          this.DOM.el.style.opacity = 1;
        },
        onComplete: () => {
          this.DOM.el.style.zIndex = 2;
          this.DOM.el.style.opacity = action === 'hide' ? 0 : 1;
          resolve();
        }
      });
    });
  }
}
