import React, { useState } from "react";
import { without } from "lodash";
import { useSelector } from "react-redux";

export const ModalInConfigurator = (props) => {
  const { json } = useSelector((state) => state.configurator);
  const { activeSteps } = useSelector((state) => state.configurator);

  const options = props.options;
  const stepIndex = options.stepIndex;
  const stepValues = options.stepValues;

  const checkedBoxesState = () => {
    const stepValue = stepValues[stepIndex];

    if (!stepValue) {
      return [];
    }

    const optionsList = options.tempItemsList.filter(value => stepValue.option_id.includes(value.id))

    return optionsList
  }

  const checkedRadioState = () => {
    const stepValue = stepValues[stepIndex];

    if (!stepValue) {
      return {};
    }

    const radioButtonSelected = options.tempItemsList.find(value => stepValue.option_id == value.id)

    return radioButtonSelected;
  }

  const [checkedBoxes, setCheckedBoxes] = useState(checkedBoxesState);
  const [checkedRadio, setCheckedRadio] = useState(checkedRadioState);
  const [description, setDescription] = useState("");
  const [checkedSubRadio, setCheckedSubRadio] = useState(null);

  const optionsHandler = ({ id }) => id;

  const handleCheckboxChange = (item) => {
    updateStepValues(item);

    if (checkedBoxes.includes(item)) {
      const filteredArray = without(checkedBoxes, item);
      setCheckedBoxes(filteredArray);
    } else {
      setCheckedBoxes([...checkedBoxes, item]);
    }

    setDescription(item.attributes.description);
  };

  const handleRadioChange = (data) => {
    updateStepValues(data);
    setCheckedRadio(data);
    setDescription(data.attributes.description);
  };

  const handleSubRadioChange = (data) => {
    setCheckedSubRadio(data);
  };

  const updateStepValues = (item) => {
    const attributes = item.attributes;
    const isStepValuesEmpty = Object.keys(stepValues).length === 0;
    let stepValue = stepValues[stepIndex];
    const itemId = item.id;

    if (!isStepValuesEmpty && stepValue) {
      let optionIds = stepValue.option_id;

      if (Array.isArray(optionIds)) {
        // stepValue.option_id = optionIds.includes(itemId) ?
        //                       without(optionIds, itemId) :
        //                       optionIds.push(itemId);

        if (optionIds.includes(itemId)) {
          stepValue.option_id = without(optionIds, itemId);
        } else {
          optionIds.push(itemId);
        }
      } else {
        stepValue.option_id = itemId;
      }

      stepValue.value = attributes.value;
      stepValue.step_id = attributes.configurator_step_id;
    }
  }

  const previousStepIds = () => {
    const ids = [];

    for (let i = 0; i < stepIndex; i++) {
      ids.push(options.groups[Object.keys(options.groups)[i]].id);
    }

    return ids;
  };

  const renderOptions = (item) => {
    // const isFirstStep = props.options.stepIndex === 0

    // const isBelongToFirstStep =
    //   item.relationships.dependent_options.data.filter(
    //     (option) => props.options.groups.group0.id === option.id
    //   )
    // const itemOptionsIds =
    //   item.relationships.dependent_options.data.map(optionsHandler)
    // const prevId = isFirstStep ? false : previousStepIds()
    // const idInObject = () =>
    //   prevId.name
    //     ? item.relationships.dependent_options.data.length
    //       ? item.relationships.dependent_options.data.some(
    //           (depend) => depend.id === prevId.id
    //         )
    //       : true
    //     : true
    // const idInArray = () => {
    //   if (
    //     props.options.stepIndex === 3 &&
    //     !props.options.confTitle.includes('VF')
    //   ) {
    //     return (
    //       itemOptionsIds.every((elem) => prevId.includes(elem)) &&
    //       isBelongToFirstStep
    //     )
    //   }

    //   if (props.options.stepIndex >= 4) {
    //     return !!item.relationships.dependent_options.data.filter((option) =>
    //       prevId.includes(option.id)
    //     ).length
    //   }

    //   return (
    //     prevId.every((elem) => itemOptionsIds.includes(elem)) &&
    //     isBelongToFirstStep
    //   )
    // }
    // const isDepended = Array.isArray(prevId) ? idInArray() : idInObject()

    // const visibleClassname = isFirstStep || isDepended ? '' : 'is-hidden'

    const { stepIndex, groups } = options;
    const data = json.included;

    let isVisible = true;

    // go through all the previous steps
    for (const step of Array.from(Array(stepIndex).keys())) {
      const filteredSteps = activeSteps.filter((_item, index) => index === step);
      const options = filteredSteps[0].relationships.configurator_options.data;
      // list all IDs in the step
      const stepIds = options
        .reduce((acc, v) => {
          const foundData = data.find(
            (el) => el.id === v.id && el.type === v.type
          );

          if (foundData) return [...acc, foundData];
          return [...acc];
        }, [])
        .map((v) => v.id);
      const stepGroup = groups[`group${step}`]; // object | array
      // list of selected IDs in the stap
      const stepSelectedIds = Array.isArray(stepGroup)
        ? stepGroup.map((v) => v.id)
        : [stepGroup.id];
      // list of dependent IDs of the current option
      const dependIds = item.relationships.dependent_options.data.map(
        (v) => v.id
      );
      // incoming dependencies in the step
      const incomeDependStepIds = stepIds.filter((v) => dependIds.includes(v));
      // incoming selected dependencies in the step
      const incomeDependStepSelectedIds = stepSelectedIds.filter((v) =>
        incomeDependStepIds.includes(v)
      );

      // list of except IDs of the current option
      const exceptIds = item.relationships.except_options.data.map(
        (v) => v.id
      );

      // incoming exceptions in the step
      const incomeExceptStepIds = stepIds.filter((v) => exceptIds.includes(v));
      // incoming selected exceptions in the step
      const incomeExceptStepSelectedIds = stepSelectedIds.filter((v) =>
        incomeExceptStepIds.includes(v)
      );

      const dependCondition = (incomeDependStepIds.length > 0 &&
                              !incomeDependStepSelectedIds.length)

      const exceptCondition = (incomeExceptStepIds.length > 0 &&
                              incomeExceptStepSelectedIds.length)

      if (exceptCondition || dependCondition) {
        isVisible = false;
        break;
      }
    }

    const visibleClassname = isVisible ? "" : "is-hidden";

    const isCheckedItemOrNull = (item) => {
      const optionId = stepValues[stepIndex]?.option_id;

      if (Array.isArray(optionId)) {
        return optionId.includes(item.id)
      }

      return optionId === item.id || null
    };

    if (options.tempMultiple) {
      const disabledArray = checkedBoxes.flatMap((checked) =>
        checked.attributes.excluded.map((ex) => ex.toString())
      );
      const disabledClassname = disabledArray.includes(item.id)
        ? "is-disabled"
        : "";

      // CHECKBOXES ARE HERE
      return (
        <li className={visibleClassname} key={`checkboxItem-${item.id}`}>
          <label className={`configurator-radio-label ${disabledClassname}`}>
            <span className="configurator-radio-name">
              {item.attributes.name}
            </span>
            <input
              className="configurator-radio-input"
              type="checkbox"
              name={item.id}
              onChange={() => handleCheckboxChange(item)}
              disabled={disabledArray.includes(item.id)}
              checked={isCheckedItemOrNull(item)}
            />
            <span className="configurator-radio-mark" />
          </label>
        </li>
      );
    } else {
      // RADIOBUTTONS ARE HERE
      return (
        <li className={visibleClassname} key={`radioItem-${item.id}`}>
          <label className="configurator-radio-label">
            <span className="configurator-radio-name">
              {item.attributes.name}
            </span>
            <input
              className="configurator-radio-input"
              type="radio"
              onChange={() => handleRadioChange(item)}
              name="radioGroup"
              checked={isCheckedItemOrNull(item)}
            />
            <span className="configurator-radio-mark" />
          </label>
        </li>
      );
    }
  };

  const renderSubOptions = (item) => {
    return (
      <li key={`radioSubItem-${item.id}`}>
        <label className="configurator-radio-label">
          <span className="configurator-radio-name">
            {item.attributes.name}
          </span>
          <input
            className="configurator-radio-input"
            type="radio"
            onChange={() => handleSubRadioChange(item)}
            name="radioSubGroup"
          />
          <span className="configurator-radio-mark" />
        </label>
      </li>
    );
  };

  return (
    <div className="configurator-modal">
      <div className="configurator-modal__header">
        <h3 className="configurator-modal__title">{options.tempTitle}</h3>
        <button className="btn" onClick={() => props.closeModal(false)}>
          Закрыть
        </button>
      </div>
      <div className="configurator-modal__content">
        <ul className="configurator-modal__list">
          {options.tempItemsList.map(renderOptions)}
        </ul>
        {options.tempSubStep && (
          <>
            <h3 className="configurator-modal__title is-mb-30">
              {options.tempSubStep.attributes.name}
            </h3>
            <ul className="configurator-modal__list is-horizontal">
              {options.tempSubStep.options.map(renderSubOptions)}
            </ul>
          </>
        )}
        {description && (
          <div
            className="configurator-modal__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
      <button
        className="configurator-modal__confirm-btn btn-theme-bg-blue"
        onClick={() => {
          if (checkedSubRadio) {
            props.chosenItems(checkedRadio, checkedSubRadio);
          } else {
            options.tempMultiple
              ? props.chosenItems(checkedBoxes)
              : props.chosenItems(checkedRadio);
          }
        }}
      >
        <span>Подтвердить</span>
      </button>
    </div>
  );
};
