import React, { Component } from "react";
import { useTranslation } from 'react-i18next';

class Clients extends Component {
  state = {
    page: 1
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      page: this.state.page++
    });

    const data = new FormData(event.target);

    fetch('/json/clients.json', {
      method: 'POST',
      body: data
    })
  }

  renderLogos = () => {
    return this.props.imgs.map(({ src, text }, index) => (
      <div className="clients-logo-container media-container" key={`logo-${index}`}>
        <div className="media-container__appear is-white"/>
        <div className="media-container__appear is-color"/>
        <img src={src} alt={text} className="clients-logo"/>
      </div>
    ))
  }

  render() {
    return (
      <form className="clients-wrap" onSubmit={this.handleSubmit}>
        <div className="clients-logo-wrap">
          {this.renderLogos()}
          <input type="hidden" name="page" value={this.state.page}/>
        </div>
        {/* <button className="clients-btn-more">Показать больше</button> */}
      </form>
    )
  }
}

export default function ClientsBlock(props) {
  const { t } = useTranslation();

  if (props.imgs.length === 0) {
    return <></>
  }

  return (
    <section className="clients-section">
      <div className="clients-content-wrapper">
        <h2 className="clients-title j-text-appear">
        <span className="text-appear-wrap">
          <span className="text-appear">
            {t('Clients')}
          </span>
        </span>
        </h2>
        <Clients imgs={props.imgs}/>
      </div>
    </section>
  )
}
