import { getConfiguratorData, getStepData } from "../../api/configurator";

export const DATA_CONFIG_REQUEST = "DATA_CONFIG_REQUEST";
export const DATA_CONFIG_LOADED = "DATA_CONFIG_LOADED";
export const TOGGLE_RESULT_MODAL = "TOGGLE_RESULT_MODAL";
export const FILTER_STEPS_DATA = "FILTER_STEPS_DATA";
export const CONFIRM_SELECTION = "CONFIRM_SELECTION";
export const ADD_ORDER = "ADD_ORDER";
export const STEP_DATA_REQUEST = "STEP_DATA_REQUEST";
export const STEP_DATA_RECEIVE = "STEP_DATA_RECEIVE";
export const SUBMIT_RESULT = "SUBMIT_RESULT";
export const RESET_CONFIGURATOR = "RESET_CONFIGURATOR";

export const configuratorRequest = (id) => ({
  id,
  type: DATA_CONFIG_REQUEST,
});

export const configuratorReceive = (response) => ({
  type: DATA_CONFIG_LOADED,
  payload: response,
});

export const getConfiguratorDataFx = (id) => async (dispatch) => {
  dispatch(configuratorRequest(id));
  const data = await getConfiguratorData(id);

  dispatch(configuratorReceive(data));
};

export const stepRequest = (id, steps) => ({
  id,
  steps,
  type: STEP_DATA_REQUEST,
});

export const stepReceive = (response) => ({
  type: STEP_DATA_RECEIVE,
  payload: response,
});

export const getStepDataFx =
  (id: any, groups: any, steps: any, index: number, selectedOptionId: number | null) =>
  async (dispatch: any) => {
    dispatch(stepRequest(id, steps));
    const data = await getStepData(id, groups, steps);

    if (data && data.data) { // data.data is empty array or array of objects
      dispatch(stepReceive({ result: data, index, selectedOptionId }));
    }
  };

export const filterStepsData = (data) => ({
  type: FILTER_STEPS_DATA,
  payload: data,
});

export const confirmSelection = (data) => ({
  type: CONFIRM_SELECTION,
  payload: data,
});

export const submitResult = (data) => ({
  type: SUBMIT_RESULT,
  payload: data,
});

export const addOrder = (data) => ({
  type: ADD_ORDER,
  payload: data,
});

export const toggleResultModal = (state) => ({
  type: TOGGLE_RESULT_MODAL,
  payload: state,
});

export const resetConfigurator = () => ({
  type: RESET_CONFIGURATOR,
});
