import React from "react";

export const PageReveal = () => {
  return(
    <div className="revealer">
      <div className="revealer__layer is-black"></div>
      <div className="revealer__layer is-blue"></div>
    </div>
  );
}
