import React from "react";
import { Doc } from "./types";
import { Doc as DocIcon } from "../../partials/Icons";

type Props = {
  document: Doc;
};

const SearchResultItem = (props: Props) => {
  const { document } = props;
  return (
    <a
      href={document.path}
      target="_blank"
      key={document.id}
      className="documents-search__result__item"
    >
      <DocIcon />
      {document.name}
    </a>
  );
};

export default SearchResultItem;
