import React from "react";
import { YouTube } from "../../Icons";

export default function Socials({ settings }) {
  return (
    <div className="socials-wrap">
      <a
        href={settings.social_youtube ? settings.social_youtube : "#"}
        className="social-icon"
      >
        <YouTube />
      </a>
    </div>
  );
}
