import React from "react";
import MenuLinks from './MenuLinks';

export default function Menu() {

  return (
    <nav className="navbar-menu">
      <MenuLinks className="navbar-menu__item" />
    </nav>
  )
}
