import React, {PureComponent} from "react";
import {LeftControl, RightControl, ProgressbarIcon, ProgressBarOneLineIcon} from "../../partials/Icons";
import {TimelineMax} from "gsap";
import SplitText from "../../SplitText";
import api from "../../../api";
import {laptopWidth} from "../../constants";


const getFacts = () => api.get('/facts');
const circle1Parts = 9;
const circle1Radius = 223;
const circle2Parts = 8;
const circle2Radius = 260;
const circle3Parts = 17;
const circle3Radius = 172;
const slideHeight = window.screen.availWidth < laptopWidth ? 200 : 250;

export default class AboutTimeline extends PureComponent {
  state = {
    active: 0,
    prevBtnDisabled: true,
    nextBtnDisabled: false,
    slideList: []
  }

  componentDidMount() {
    this.getData();
    this.timeline = new TimelineMax();
    this.points = document.querySelectorAll('.svg-point');
    this.pointsOneLine = document.querySelectorAll('.svg-one-line-point');
    this.coefficient = 3.14 * circle1Radius * (360 / circle1Parts) / 180;
    this.coefficient2 = 3.14 * circle2Radius * (360 / circle2Parts) / 180;
    this.coefficient3 = 3.14 * circle3Radius * (360 / circle3Parts) / 180;
    this.circle1 = document.querySelector('#svgCircleProgressPath1');
    this.circle1Length = parseInt(window.getComputedStyle(this.circle1).strokeDashoffset);
    this.circle2 = document.querySelector('#svgCircleProgressPath2');
    this.circle2Length = parseInt(window.getComputedStyle(this.circle2).strokeDashoffset);
    this.circle3 = document.querySelector('#svgCircleProgressPath3');
    this.circle3Length = parseInt(window.getComputedStyle(this.circle3).strokeDashoffset);
    this.circleSvg2 = document.querySelector('#svgCircleIcon2');
    this.height = slideHeight;

    this.setActive();
  }

  async getData() {
    const response = await getFacts()
    const facts = response.data.data.map(fact => fact.attributes);

    this.setState({slideList: facts});
    this.pointsHandler();
    this.secondCircleHandler();
  }

  setActive = (isActive = true) => {
    if (this.state.active >= this.points.length) {
      return;
    }

    this.points.forEach((item) => {
      item.classList.remove('is-active', 'is-seen')
    });
    this.pointsOneLine.forEach((item) => {
      item.classList.remove('is-active', 'is-seen')
    });

    this.points[this.state.active].classList[isActive ? 'add' : 'remove']('is-active');
    this.pointsOneLine[this.state.active].classList[isActive ? 'add' : 'remove']('is-active');
    for (let i = 0; i <= this.state.active; i++) {
      this.points[i].classList.add('is-seen');
      this.pointsOneLine[i].classList.add('is-seen');
    }
  }

  pointsHandler = () => {
    this.points.forEach((point, index) => {
      if (index >= this.state.slideList.length) {
        point.classList.add("is-inactive");
      }

      point.addEventListener("click", () => {
        if (index === this.state.active) return;

        this.changeIndex(index);
      });
    });
  }

  secondCircleHandler = () => {
    if (this.state.slideList.length <= 9) {
      this.circleSvg2.style.display = "none";
    }
  }

  changeIndex = (number) => {
    if (number >= this.state.slideList.length) {
      this.setState(() => ({nextBtnDisabled: true}));

      return;
    }

    if (number < 0) {
      this.setState(() => ({prevBtnDisabled: true, nextBtnDisabled: false}));

      return;
    }

    const dashoffset = this.coefficient * number;
    const dashoffset2 = this.coefficient2 * (number - circle1Parts); // отнимаем точки первого круга
    const dashoffset3 = this.coefficient3 * number;

    this.setState(() => ({
      active: number,
      prevBtnDisabled: false
    }), this.setActive);
    this.animateCarousel(`-${this.height * number}`);

    if (this.state.active <= circle1Parts) {
      this.animateSVG(-dashoffset, 1);
    } else {
      this.animateSVG(-dashoffset2, 2);
    }

    this.animateSVG(-dashoffset3, 3);
  }

  /**
   * Анимация заполнения окружности
   * @param {Number} length - длина дуги, которую нужно заполнить
   * @param {Number} circleNumber - номер окружности, 1 - внутренняя, 2 - внешняя, 3 - адаптивная версия
   */
  animateSVG = (length, circleNumber) => {
    if (circleNumber === 1) {
      this.circle1.style.strokeDashoffset = this.circle1Length + length;
      this.circle2.style.strokeDashoffset = this.circle2Length; // второй круг должен быть незаполненным
    }

    if (circleNumber === 2) {
      this.circle2.style.strokeDashoffset = this.circle2Length + length;
      this.circle1.style.strokeDashoffset = this.circle1Length * (circle1Parts - 1); // первый круг должен быть заполненным
    }

    if (circleNumber === 3) {
      this.circle3.style.strokeDashoffset = this.circle3Length + length;
    }
  }

  animateCarousel = (height) => {
    this.timeline.to(
      '.j-timeline-carousel',
      0.4,
      {y: height}
    );
  }

  render() {
    return (
      <section id="anchorHistory" className="timeline-section j-anchor-container">
        <ProgressbarIcon/>
        <ProgressBarOneLineIcon/>
        <TimelineSlides slides={this.state.slideList} active={this.state.active}/>
        <div className="timeline-controls">
          <button className="timeline-btn is-prev"
                  onClick={this.changeIndex.bind(this, this.state.active - 1)}
                  disabled={this.state.prevBtnDisabled}>
            <LeftControl/>
          </button>
          <button className="timeline-btn is-next"
                  onClick={this.changeIndex.bind(this, this.state.active + 1)}
                  disabled={this.state.nextBtnDisabled}>
            <RightControl/>
          </button>
        </div>
      </section>
    )
  }
}

class TimelineSlide extends PureComponent {
  componentDidMount() {
    const splitText = new SplitText;
    const containerWidth = 160;
    const text = this.textTag.innerText;
    this.textTag.innerText = '';

    for (let line of splitText.splitTextByActualLineWidth(this.textTag, text, containerWidth)) {
      const blockElement = document.createElement('p');
      const lineElement = document.createElement('span');
      blockElement.appendChild(lineElement);
      lineElement.textContent = line;
      this.textTag.appendChild(blockElement);
    }
  }

  getTextRef = (node) => this.textTag = node;

  render() {
    return (
      <div className={`timeline-slide ${this.props.active}`}>
        <div className="timeline-year"><span>{this.props.year}</span></div>
        <div className="timeline-text"
             dangerouslySetInnerHTML={{__html: this.props.text}}
             ref={this.getTextRef}
        />
      </div>
    )
  }
}

const TimelineSlides = (props) => {
  const renderSlides = () => {
    return props.slides.map((slide, index) => {
      const activeClass = index === props.active ? 'is-active' : '';

      return <TimelineSlide
        year={slide.year}
        text={slide.description}
        active={activeClass}
        key={index}
      />
    });
  }

  return (
    <div className="timeline-info">
      <div className="j-timeline-carousel">
        {renderSlides()}
      </div>
    </div>
  )
}
