import { TweenLite } from 'gsap';

export const openConfigurator = () => {
  document.body.classList.add("body-fixed");

  TweenLite.to(
    ".configurator",
    0.8,
    {
      xPercent: 100,
      ease: Power3.easeInOut,
      onComplete: () => {
        TweenLite.to(
          ".configurator .configurator-inputs-container",
          0.3,
          { opacity: 1, ease: Power3.easeInOut }
        );
        TweenLite.to(
          ".configurator .configurator-header-wrap .icon-logo",
          0.3,
          { yPercent: -150, ease: Power3.easeInOut }
        );
        TweenLite.to(
          ".configurator .configurator-header-wrap .btn",
          0.3,
          { yPercent: -100, ease: Power3.easeInOut, delay: 0.2 }
        );
        TweenLite.to(
          ".configurator .configurator-title__span",
          0.3,
          { yPercent: -100, ease: Power3.easeInOut, delay: 0.4 }
        );
      }
    }
  );
}

export const closeConfigurator = () => {
  document.body.classList.remove("body-fixed");

  TweenLite.to(
    ".configurator .configurator-inputs-container",
    0.2,
    { opacity: 0, ease: Power3.easeInOut }
  );
  TweenLite.to(
    ".configurator .configurator-header-wrap .icon-logo",
    0.2,
    { yPercent: 0, ease: Power3.easeInOut }
  );
  TweenLite.to(
    ".configurator .configurator-header-wrap .btn",
    0.2,
    { yPercent: 0, ease: Power3.easeInOut, delay: 0.2 }
  );
  TweenLite.to(
    ".configurator .configurator-title__span",
    0.2,
    { yPercent: 0, ease: Power3.easeInOut, delay: 0.4 }
  );

  TweenLite.to(
    ".configurator",
    0.8,
    {
      xPercent: 0,
      ease: Power3.easeInOut,
      delay: 0.7
    }
  );
}

export const closeResultModal = (id) => {
  document.body.classList.remove("body-fixed");

  TweenLite.to(
    `${id} .configurator-inputs-container`,
    0.2,
    { opacity: 0, ease: Power3.easeInOut }
  );
  TweenLite.to(
    `${id} .configurator-header-wrap .icon-logo`,
    0.2,
    { yPercent: 0, ease: Power3.easeInOut }
  );
  TweenLite.to(
    `${id} .configurator-header-wrap .btn`,
    0.2,
    { yPercent: 0, ease: Power3.easeInOut, delay: 0.2 }
  );
  TweenLite.to(
    `${id} .configurator-title__span`,
    0.2,
    { yPercent: 0, ease: Power3.easeInOut, delay: 0.4 }
  );

  TweenLite.to(
    id,
    0.8,
    {
      xPercent: 0,
      ease: Power3.easeInOut,
      delay: 0.7
    }
  );
}

export const openInnerModal = (id) => {
  document.body.classList.add("body-fixed");

  TweenLite.to(
    id,
    0.8,
    {
      xPercent: 100,
      ease: Power3.easeInOut,
      onComplete: () => {
        TweenLite.to(
          `${id} .configurator-result__inputs-container`,
          0.3,
          { opacity: 1, ease: Power3.easeInOut }
        );
        TweenLite.to(
          `${id} .configurator-header-wrap .icon-logo`,
          0.3,
          { yPercent: -150, ease: Power3.easeInOut }
        );
        TweenLite.to(
          `${id} .configurator-header-wrap .btn`,
          0.3,
          { yPercent: -100, ease: Power3.easeInOut, delay: 0.2 }
        );
        TweenLite.to(
          `${id} .configurator-title__span`,
          0.3,
          { yPercent: -100, ease: Power3.easeInOut, delay: 0.4 }
        );
      },
    }
  );
}
