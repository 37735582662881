import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { TimelineLite, TweenLite } from "gsap";
import { withTranslation, WithTranslation } from "react-i18next";

import { Logo } from "../Icons";
import Menu from "./partials/Menu";
import { HeaderContacts } from "./partials/Contacts";
import MobileMenu from "./partials/MobileMenu";
import { showSearchBlock } from "../search/SearchAnimations";

const menuPadding = 80;
const animationDuration = 0.2;
const animationTextDelay = 0.5;

type Props = {
  changeColorState?: any;
  transparent?: boolean;
} & RouteComponentProps &
  WithTranslation;

class Navbar extends PureComponent<Props> {
  navbar;
  inverseBlocks;
  hiddenText;
  mediaContainers;

  state = {
    colored: false,
  };

  getNavbarRef = (node) => (this.navbar = node);

  componentDidMount() {
    const tlMenu = new TimelineLite({ delay: 0.6 });
    const searchBtnAnimation = () =>
      TweenLite.to(".navbar-search-span", animationDuration, { y: 0 });
    const langIconAnimation = () =>
      TweenLite.to(".navbar-locale", animationDuration, { opacity: 1 });
    this.inverseBlocks = document.querySelector(".j-menu-inverse");
    this.hiddenText = document.querySelectorAll(".j-text-appear");
    this.mediaContainers = document.querySelectorAll(".media-container");

    this.toggleMenuColor();

    window.addEventListener("scroll", () => {
      // if (window.screen.availWidth >= laptopWidth) {
      //   this.toggleMenuColor();
      // }
      this.showText();
      this.showMediaContainer();
    });

    tlMenu.staggerTo(
      ".navbar-menu__item",
      animationDuration,
      { y: 0 },
      0.1,
      0,
      searchBtnAnimation
    );
    tlMenu.staggerTo(
      ".navbar-link-span",
      animationDuration,
      { y: 0 },
      0.1,
      0,
      langIconAnimation
    );
    this.showText();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.inverseBlocks = document.querySelector(".j-menu-inverse");
    this.toggleMenuColor();

    if (this.props.changeColorState) {
      if (prevState.colored !== this.state.colored) {
        this.props.changeColorState(this.state.colored);
      }
    }
  }

  toggleMenuColor = () => {
    if (this.inverseBlocks) {
      const position = this.inverseBlocks.offsetTop;

      if (position <= window.scrollY + menuPadding) {
        this.setState({ colored: true });
      } else {
        this.setState({ colored: false });
      }
    }
  };

  showText = () => {
    this.hiddenText.forEach((string) => {
      const bounding = string.getBoundingClientRect();
      const hiddenSpan = string.querySelectorAll(".text-appear");

      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        TweenLite.to(hiddenSpan, 0.4, { y: 0, delay: animationTextDelay });
      }
    });
  };

  showMediaContainer = () => {
    if (this.mediaContainers.length) {
      this.mediaContainers.forEach((container) => {
        const bounding = container.getBoundingClientRect();

        if (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.right <=
            (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <=
            (window.innerHeight || document.documentElement.clientHeight)
        ) {
          container.classList.add("is-shown");
        }
      });
    }
  };

  render() {
    const { t, transparent } = this.props;
    return (
      <div
        className={`navbar-wrapper ${
          this.state.colored ? "navbar-theme-colored" : ""
        } ${this.props.transparent && "navbar-wrapper-transparent"}`}
        ref={this.getNavbarRef}
      >
        <Link to="/" className="navbar-logo">
          <Logo />
        </Link>
        <MobileMenu />
        <Menu />
        <button className="navbar-search-btn" onClick={showSearchBlock}>
          <span className="navbar-search-span">{t("Search")}</span>
        </button>
        <HeaderContacts />
      </div>
    );
  }
}

export default withTranslation()(withRouter(Navbar));
