import React, {Component} from "react";
import {LeftControl, RightControl} from "../../partials/Icons";
import {TweenMax} from "gsap";
import api from "../../../api";


const getGalleries = () => api.get('/galleries')

export default class AboutCarousel extends Component {
  state = {
    images: []
  }

  async componentDidMount() {
    const response = await getGalleries()
    const images = response.data.data.map(image => image.attributes);

    this.setState({images})

    const nodes = document.querySelectorAll('.j-about-slide');
    this.slides = [];
    this.current = 0;

    [].map.call(nodes, (node) => {
      this.slides.push(new Slide(node, {}));
    });

    this.navigation = new Navigation(this.boxnav, {
      next: () => this.navigate('right'),
      prev: () => this.navigate('left')
    });

    this.slidesTotal = this.slides.length;
    this.navigation.createDots(this.slidesTotal);
    this.slides[this.current].setCurrent();
    this.navigation.setCurrent(this.current);
  }

  getControlsRef = (node) => {this.boxnav = node};

  navigate(direction) {
    if ( this.isAnimating ) return;
    this.isAnimating = true;

    // позиция следующего/предыдущего слайдов
    const nextSlidePos = direction === 'right'
      ? (this.current < this.slidesTotal - 1) ? this.current + 1 : 0
      : (this.current > 0) ? this.current - 1 : this.slidesTotal - 1;

    Promise.all([this.slides[this.current].hide(direction), this.slides[nextSlidePos].show(direction)])
      .then(() => {
        this.slides[this.current].setCurrent(false);
        this.current = nextSlidePos;
        this.slides[this.current].setCurrent();
        this.navigation.setCurrent(this.current);
        this.isAnimating = false;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  renderImages = () => {
    return this.state.images.map((image, index) => {
      return <div className="about-carousel-slide j-about-slide" key={`about-img-${index}`}>
        <div className="about-carousel-slide__wrap">
          <div className="about-carousel-slide__img" style={{backgroundImage: `url(${image.image})`}}></div>
          <h3 className="about-carousel-slide__title">{image.name}</h3>
        </div>
      </div>
    });
  }

  render() {
    return (
      <section id="anchorGallery" className="about-carousel-section j-anchor-container">
        <div className="about-carousel">
          <div className="about-slideshow">
            {this.renderImages()}
          </div>
          <div className="about-carousel-controls" ref={this.getControlsRef}>
            <button className="about-carousel-btn is-prev" >
              <LeftControl />
            </button>
            <button className="about-carousel-btn is-next" >
              <RightControl />
            </button>
          </div>
        </div>
      </section>
    )
  }
}

class Slide {
  constructor(el, settings) {
    this.DOM = {el: el};

    this.settings = {
      detailsEl: null
    }

    Object.assign(this.settings, settings);
    this.DOM.wrap = this.DOM.el.querySelector('.about-carousel-slide__wrap');
    this.DOM.img = this.DOM.wrap.querySelector('.about-carousel-slide__img');
    this.config = {
      animation: {
        duration: 1.2,
        ease: Expo.easeInOut
      }
    };
  }

  setCurrent(isCurrent = true) {
    this.DOM.el.classList[isCurrent ? 'add' : 'remove']('is-active');
  }

  hide(direction) {
    return this.toggle('hide', direction);
  }

  show(direction) {
    this.DOM.el.style.zIndex = 3;
    return this.toggle('show', direction);
  }

  toggle(action, direction) {
    return new Promise((resolve, reject) => {
      if ( action === 'show' ) {
        TweenMax.to(this.DOM.wrap, this.config.animation.duration, {
          ease: this.config.animation.ease,
          startAt: {x: direction === 'right' ? '100%' : '-100%'},
          x: '0%'
        });
      }

      TweenMax.to(this.DOM.img, this.config.animation.duration, {
        ease: this.config.animation.ease,
        startAt: action === 'hide' ? {} : {x: direction === 'right' ? '-100%' : '100%', scale: 1.1},
        x: '0%',
        scale: action === 'hide' ? 1.1 : 1,
        onStart: () => {
          this.DOM.img.style.transformOrigin = action === 'hide' ?
            direction === 'right' ? '100% 50%' : '0% 50%':
            direction === 'right' ? '0% 50%' : '100% 50%';
          this.DOM.el.style.opacity = 1;
        },
        onComplete: () => {
          this.DOM.el.style.zIndex = 2;
          this.DOM.el.style.opacity = action === 'hide' ? 0 : 1;
          resolve();
        }
      });
    });
  }
}

class Navigation {
  constructor(el, settings) {
    this.DOM = {el: el};

    this.settings = {
      next: () => false,
      prev: () => false
    }
    Object.assign(this.settings, settings);
    this.DOM.prevCtrl = this.DOM.el.querySelector('.is-prev');
    this.DOM.nextCtrl = this.DOM.el.querySelector('.is-next');

    this.initEvents();
  }

  setCurrent(index) {
    this.DOM.dots.forEach((item) => {item.classList.remove('is-active')});
    this.DOM.dots[index].classList.toggle('is-active');
  }

  createDots(slides) {
    const wrap = document.createElement('div');
    this.DOM.dots = [];

    wrap.classList.add('about-carousel-nav');

    for (let i = 0; i < slides; i++) {
      const dot = document.createElement('div');

      dot.classList.add('about-carousel-nav__item');
      wrap.appendChild(dot);
      this.DOM.dots.push(dot);
    }
    this.DOM.el.insertBefore(wrap, this.DOM.prevCtrl);
  }

  initEvents() {
    this.DOM.prevCtrl.addEventListener('click', () => this.settings.prev());
    this.DOM.nextCtrl.addEventListener('click', () => this.settings.next());
  }
}
