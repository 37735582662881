import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import InputMask from "react-input-mask";
import { useTranslation, withTranslation } from "react-i18next";
import { YMaps, Map, Placemark } from "react-yandex-maps";

import HighlightAnchor from "../../HighlightAnchor";
import Footer from "../../partials/footer/Footer";
import Navbar from "../../partials/navbar/Navbar";

const anchors = new HighlightAnchor();

class ContentContacts extends Component {
  state = {
    departments: [],
    settings: {},
  };

  async componentDidMount() {
    await this.getData();
    anchors.init();
  }

  getData = () => {
    fetch("/api/v1/settings", {
      headers: {
        Locale: window.localStorage.getItem("locale") || "ru",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const settings = data.data.map((settings) => settings.attributes)[0];

        this.setState(() => ({ settings }));
      })
      .catch((error) => console.error(error));
    fetch("/api/v1/departments", {
      headers: {
        Locale: window.localStorage.getItem("locale") || "ru",
      },
    })
      .then((result) => result.json())
      .then((res) => {
        const departments = res.data.map((fact) => fact.attributes);

        this.setState({ departments });
      })
      .catch((error) => console.error(error));
  };

  renderOffices() {
    return this.state.departments.map((office, index, arr) => {
      return (
        <OfficeContacts
          title={office.name}
          phone={office.phone}
          fax={office.fax}
          email={office.email}
          arrayAmount={arr}
          fakeKey={index}
          key={index}
        />
      );
    });
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Navbar />
        <main className="main__theme-blue">
          <section className="contacts-section section_theme_padding-top">
            <ul className="breadcrumbs is-absolute">
              <li>
                <a href="/">{t("Main page")}</a>
              </li>
              <li>{t("Contacts")}</li>
            </ul>

            <section className="contacts-navigation">
              <nav className="anchors-list">
                <AnchorLink
                  href="#anchorMain"
                  offset="150"
                  className="anchor-link j-anchor-link is-active"
                >
                  {t("Main office")}
                </AnchorLink>
                <AnchorLink
                  href="#anchorOffices"
                  offset="150"
                  className="anchor-link j-anchor-link"
                >
                  {t("Contact departments")}
                </AnchorLink>
                <AnchorLink
                  href="#anchorCallback"
                  offset="150"
                  className="anchor-link j-anchor-link"
                >
                  {t("Callback")}
                </AnchorLink>
              </nav>
            </section>

            <section className="contacts-container">
              <div className="contacts-content">
                <h1 className="contacts-title j-text-appear">
                  <span className="text-appear-wrap">
                    <span className="text-appear">{t("Contacts")}</span>
                  </span>
                </h1>

                <div id="anchorMain" className="office-wrap j-anchor-container">
                  <div className="office-contacts">
                    <div className="office-contacts__row">
                      <div className="office-contacts__label">
                        {t("Phone")}:
                      </div>
                      <div className="office-contacts__value">
                        <a
                          href="tel:+78123299797"
                          className="office-contacts__phone"
                        >
                          +7 (812) 329-97-97
                        </a>
                      </div>
                    </div>
                    <div className="office-contacts__row">
                      <div className="office-contacts__label">{t("Fax")}:</div>
                      <div className="office-contacts__value">
                        <span className="office-contacts__phone">
                          +7 (812) 329-97-92
                        </span>
                      </div>
                    </div>
                  </div>
                  <a
                    href="mailto:info@elteh.ru"
                    className="office-contacts__email"
                  >
                    info@elteh.ru
                  </a>
                </div>
              </div>
            </section>
          </section>
          {/* isuefhiuh */}
          <section className="contacts-map-section">
            <div className="map-container">
              <YMaps>
                <Map
                  defaultState={{
                    center: [
                      this.state.settings.latitude,
                      this.state.settings.longitude,
                    ],
                    zoom: 12,
                  }}
                  width="100%"
                  height="100%"
                >
                  <Placemark
                    geometry={[
                      this.state.settings.latitude,
                      this.state.settings.longitude,
                    ]}
                  />
                </Map>
              </YMaps>
            </div>
          </section>
          <section
            id="anchorOffices"
            className="contacts-section j-anchor-container"
          >
            <div className="contacts-content">
              {this.renderOffices()}

              <h2
                id="anchorCallback"
                className="contacts-subtitle j-anchor-container"
              >
                {t("Write to us")}
              </h2>
              <form action="" className="product-form">
                <div className="form-group">
                  <input
                    type="text"
                    className="input-default"
                    defaultValue=""
                    placeholder={t("Name")}
                    required
                  />
                  <span className="form-require-text">{t("Optional")}</span>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="input-default"
                    defaultValue=""
                    placeholder="E-mail"
                    required
                  />
                  <span className="form-require-text">{t("Optional")}</span>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="input-default"
                    defaultValue=""
                    placeholder={t("Position")}
                  />
                  <span className="form-require-text">{t("Optional")}</span>
                </div>
                <div className="form-group">
                  <InputMask
                    mask="+7(999) 999-99-99"
                    maskChar=" "
                    className="input-default"
                    placeholder={`${t("Pho")}: +7(___)___-__-__`}
                  />
                  <span className="form-require-text">{t("Optional")}</span>
                </div>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="input-default text-area-default"
                    defaultValue=""
                    placeholder={t("Message")}
                    rows="1"
                    required
                  />
                  <span className="form-require-text">{t("Optional")}</span>
                </div>
                <div className="checkbox-group">
                  <input
                    type="checkbox"
                    id="agreement"
                    className="input-checkbox"
                    name="agreement"
                  />
                  <span className="input-checkbox-mark" />
                  <label htmlFor="agreement" className="checkbox-label">
                    {t("I consent")} <a href="#">{t("Consent")}</a>{" "}
                    {t("The processing")}
                  </label>
                </div>
                <button className="submit-btn btn-theme-bg-blue">
                  <span>{t("Send")}</span>
                </button>
              </form>
            </div>
          </section>
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}

export const OfficeContacts = (props) => {
  const { t } = useTranslation();
  const lastItem =
    props.fakeKey === props.arrayAmount.length - 1 ? "is-last" : "";

  return (
    <div className={`office-wrap ${lastItem}`}>
      <h2 className="office-title">{props.title}</h2>
      <div className="office-contacts">
        <div className="office-contacts__row">
          <div className="office-contacts__label">{t("Phone")}:</div>
          <div className="office-contacts__value">
            <a href={`tel:${props.phone}`} className="office-contacts__phone">
              {props.phone}
            </a>
          </div>
        </div>
        <div className="office-contacts__row">
          <div className="office-contacts__label">{t("Fax")}:</div>
          <div className="office-contacts__value">
            <span className="office-contacts__phone">{props.fax}</span>
          </div>
        </div>
      </div>
      <a href={`mailto:${props.email}`} className="office-contacts__email">
        {props.email}
      </a>
    </div>
  );
};

export default withTranslation()(ContentContacts);
