import React, {Component} from "react";
import {Link} from "react-router-dom";
import {ChevronInCircle, Close, Configurator, Logo} from "../../partials/Icons";
import {TweenLite} from "gsap";

const Currents = {
  voltage: 'voltage',
  amperage: "amperage",
  nominalAmperage: "nominalAmperage",
  onOff: "onOff",
  chains: "chains",
  options: "options",
  blocks: "blocks"
};
const firstElement = 0;

export default class ProductConfigurator extends Component {
  state = {
    showModal: false,
    itemsList: null,
    voltageTitle: null,
    currentTitle: null,
    breakingCurrentTitle: null,
    switchTitle: null,
    chainsTitle: null,
    options: null,
    blocking: null
  }

  renderModal = () => {
    if (this.state.showModal) return <ModalVoltage options={this.state}
                                                   closeModal={prop => this.setState({showModal: prop})}
                                                   chosenItem={prop => this.chooseItem(prop)}/>;
  }

  chooseItem = (data) => {
    switch (data.attributes.configurator_step_id) {
      case "1":
        this.setState({showModal: false, voltageTitle: data.attributes.name});
        break;
      case "2":
        this.setState({showModal: false, currentTitle: data.attributes.name});
        break;
      case "3":
        this.setState({showModal: false, breakingCurrentTitle: data.attributes.name});
        break;
      case "4":
        this.setState({showModal: false, switchTitle: data.attributes.name});
        break;
      case "5":
        this.setState({showModal: false, chainsTitle: data.attributes.name});
        break;
      default:
        break;
    }
  }

  closeConfigurator = () => {
    document.body.classList.remove("body-fixed");

    TweenLite.to(
      ".configurator-inputs-container",
      0.2,
      {opacity: 0, ease: Power3.easeInOut}
    );
    TweenLite.to(
      ".configurator-header-wrap .icon-logo",
      0.2,
      {yPercent: 0, ease: Power3.easeInOut}
    );
    TweenLite.to(
      ".configurator-header-wrap .btn",
      0.2,
      {yPercent: 0, ease: Power3.easeInOut, delay: 0.2}
    );
    TweenLite.to(
      ".configurator-title__span",
      0.2,
      {yPercent: 0, ease: Power3.easeInOut, delay: 0.4}
    );

    TweenLite.to(
      ".configurator",
      0.8,
      {
        xPercent: 0,
        ease: Power3.easeInOut,
        delay: 0.7
      }
    );
  }

  getVoltage = (step) => {
    fetch("/api/v1/configurators/1?include=configurator_steps.configurator_options.dependent_options,configurator_steps.configurator_options.links")
      .then(response => response.json())
      .then(json => {
        const data = json.included;
        const filteredSteps = data.filter(item => item.type === "configurator_steps" && parseInt(item.id) === step);
        const relationships = filteredSteps[firstElement].relationships.configurator_options.data;
        const result = relationships.reduce((acc, v) => {
          const foundData = data.find((el => el.id === v.id && el.type === v.type));

          if (foundData) return [...acc, foundData];
          return [...acc]
        }, []);

        this.setState({
          tempTitle: filteredSteps[firstElement].attributes.name,
          itemsList: result,
          showModal: true,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    return (
      <div className="configurator">
        <div className="configurator-header navbar-theme-colored">
          <div className="configurator-header-wrap hide-elements">
            <Link to="/"><Logo/></Link>
            <button className="btn" onClick={this.closeConfigurator}><Close/></button>
          </div>
        </div>
        <div className="configurator-left-column">
          <p className="configurator-hint">
            Для перехода к заявке <br/>
            заполните все поля</p>
        </div>
        <div className="configurator-right-column">
          <h3 className="configurator-title">
            <span className="configurator-title__span">
              <Configurator/> Конфигуратор выключателей VF
            </span>
          </h3>
          <div className="configurator-inputs-container">
            <div className="configurator-serial-wrap">
              <span className="configurator-serial">{this.state.voltageTitle || "vfxx"}</span>
              <span className="configurator-serial">x</span>
              <span className="configurator-serial">xx</span>
              <span className="configurator-serial">xxx</span>
              <span className="configurator-serial">x</span>
              <span className="configurator-serial">xxx</span>
              <span className="configurator-serial">xx . xx</span>
            </div>
            <div className="input-group">
              <input type="text"
                     className="input-configurator"
                     placeholder="Выбор напряжения, кВ"/>
              <button className="btn__theme__configurator"
                      onClick={this.getVoltage.bind(this, 1)}>
                {this.state.voltageTitle || "Выбрать"}
              </button>
            </div>
            <div className="input-group">
              <input type="text"
                     className="input-configurator"
                     placeholder="Номинальный ток, А"
                     disabled={!this.state.voltageTitle} />
              <button className="btn__theme__configurator"
                      onClick={this.getVoltage.bind(this, 2)}
                      disabled={!this.state.voltageTitle}>
                {this.state.currentTitle || "Выбрать"}
              </button>
            </div>
            <div className="input-group">
              <input type="text"
                     className="input-configurator"
                     placeholder="Номинальный ток отключения, кА"
                     disabled={!this.state.currentTitle}/>
              <button className="btn__theme__configurator"
                      onClick={this.getVoltage.bind(this, 3)}
                      disabled={!this.state.currentTitle}>
                {this.state.breakingCurrentTitle || "Выбрать"}
              </button>
            </div>
            <div className="input-group">
              <input type="text"
                     className="input-configurator"
                     placeholder="Исполнение выключателя"
                     disabled={!this.state.breakingCurrentTitle}/>
              <button className="btn__theme__configurator"
                      onClick={this.getVoltage.bind(this, 4)}
                      disabled={!this.state.breakingCurrentTitle}>
                {this.state.switchTitle || "Выбрать"}
              </button>
            </div>
            <div className="input-group">
              <input type="text"
                     className="input-configurator"
                     placeholder="Номинальное напряжение вспомогательных цепей, В"
                     disabled={!this.state.switchTitle}/>
              <button className="btn__theme__configurator"
                      onClick={this.getVoltage.bind(this, 5)}
                      disabled={!this.state.switchTitle}>
                {this.state.chainsTitle || "Выбрать"}
              </button>
            </div>
            <div className="input-group">
              <input type="text"
                     className="input-configurator"
                     placeholder="Опции"
                     disabled={!this.state.chainsTitle}/>
              <button className="btn__theme__configurator"
                      onClick={this.getVoltage.bind(this, 6)}
                      disabled={!this.state.chainsTitle}>
                {this.state.options || "Выбрать"}
              </button>
            </div>
            <div className="input-group">
              <input type="text" className="input-configurator" placeholder="Блокировки" disabled/>
              <button className="btn__theme__configurator" disabled>Выбрать</button>
            </div>
            <footer className="configurator-footer">
              <button className="btn btn__theme__request" disabled>
                <ChevronInCircle/>
                Перейти к заявке
              </button>
            </footer>
            {this.renderModal()}
          </div>
        </div>
      </div>
    );
  }
}

/*const Modal = (step) => {
  switch (step) {
    case Currents.voltage: {
      return (

      )
    }
    default: return null
  }
}*/

export const ModalVoltage = (props) => {
  const renderOptions = (item) => {
    return <li key={`radioItem-${item.id}`}>
      <label className="configurator-radio-label" onClick={() => props.chosenItem(item)}>
        {item.attributes.name}
        <input className="configurator-radio-input" type="radio" name="radioGroup" value=""/>
        <span className="configurator-radio-mark"></span>
      </label>
    </li>
  };

  return (
    <div className="configurator-modal">
      <div className="configurator-modal__header">
        <h3 className="configurator-modal__title">{props.options.tempTitle}</h3>
        <button className="btn" onClick={() => props.closeModal(false)}>Закрыть</button>
      </div>
      <div className="configurator-modal__content">
        <ul className="configurator-modal__list">
          {props.options.itemsList.map(item => renderOptions(item))}
        </ul>
      </div>
    </div>
  );
};
