import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { lang } from '../variables/common';
import trEN from '../translations/en/common.json';
import trRU from '../translations/ru/common.json';

const resources = {
  en: {
    translation: trEN
  },
  ru: {
    translation: trRU
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang,
    fallbackLng: 'ru',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
