import React, { useCallback, useEffect, useState, useRef } from "react";
import { isIOS } from "react-device-detect";
import { LeftControl, RightControl } from "../../partials/Icons";
import { IProductVideo } from "./types";

export const ProductCarouselNavigation = ({
  data,
  active,
  onBackward,
  onForward,
}: {
  data: IProductVideo[];
  active: number;
  onBackward: (v: number) => void;
  onForward: (v: number) => void;
}) => {
  return (
    <div className="product-carousel-controls">
      <button
        className="product-carousel-btn is-prev"
        onClick={() => onBackward(active)}
        disabled={active === 0}
      >
        <LeftControl />
      </button>
      <div className="product-carousel-counter">
        <span className="is-active">{active + 1}/</span>
        <span>{data.length}</span>
      </div>
      <button
        className="product-carousel-btn is-next"
        onClick={() => onForward(active + 1)}
        disabled={active === data.length - 1}
      >
        <RightControl />
      </button>
    </div>
  );
};

const MobileVideoIOS = ({ uri }: { uri?: string | null }) => {
  if (!uri) return null;

  const videoPath = uri.split("=")[1];
  return (
    <iframe
      title="Пример работы продукта"
      className="product-video-frame product-content-end"
      src={`https://www.youtube-nocookie.com/embed/${videoPath}`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

enum Direction {
  Backward = "backward",
  Forward = "forward",
}

export const ProductVideo = ({
  videos: data,
  mobileVideo,
}: {
  videos: IProductVideo[];
  mobileVideo?: string;
}) => {
  const [isScreenVisible, setScreenVisible] = useState<boolean>(false);
  const [sceneActiveIndex, setSceneActiveIndex] = useState<number>(0);
  const [sceneActiveSource, setSceneActiveSource] = useState<string>("");

  const [direction, setDirection] = useState<Direction>(Direction.Forward);
  const isForward = direction === Direction.Forward;

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const captureHandler = useCallback(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (!canvas || !video) return;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext("2d")
      ?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  }, [canvasRef, videoRef]);

  const playVideo = useCallback(() => {
    if (!videoRef.current) return;

    setScreenVisible(true);
    captureHandler();
    videoRef.current.load();

    videoRef.current.onloadeddata = () => {
      if (!videoRef.current) return;
      videoRef.current.play();
      // videoRef.current.onplaying = () => {
      //   setScreenVisible(false)
      //   if (!videoRef.current) return
      //   videoRef.current.onplaying = null
      // }
      videoRef.current.ontimeupdate = () => {
        setScreenVisible(false);
        if (!videoRef.current) return;
        videoRef.current.ontimeupdate = null;
      };
    };
  }, [videoRef, captureHandler]);

  const nextVideoHandler = useCallback(
    (index: number) => {
      if (!isForward) {
        setSceneActiveIndex(index - 1);
        setSceneActiveSource(data[index - 1].video);
      } else {
        setSceneActiveIndex(index);
        setSceneActiveSource(data[index].video);
      }

      setDirection(Direction.Forward);
      playVideo();
    },
    [data, isForward, playVideo]
  );

  const previousVideoHandler = useCallback(
    (index: number) => {
      if (!isForward) {
        setSceneActiveIndex(index - 1);
        setSceneActiveSource(data[index - 1].back_video);
      } else {
        setDirection(Direction.Backward);
        setSceneActiveSource(data[index].back_video);
      }

      playVideo();
    },
    [data, isForward, playVideo]
  );

  useEffect(() => {
    setSceneActiveSource(data[0].video);
    nextVideoHandler(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data.length) return null;

  if (isIOS) {
    return (
      <div>
        <MobileVideoIOS uri={mobileVideo} />
      </div>
    );
  }

  return (
    <>
      <div className="product-video-carousel">
        <div
          className="product-preview-container"
          style={{
            opacity: isScreenVisible ? 1 : 0,
          }}
        >
          <canvas ref={canvasRef} className="product-preview-canvas" />
        </div>
        <video className="product-player" ref={videoRef} muted>
          <source src={sceneActiveSource} />
        </video>
      </div>
      {data.length > 1 && !isIOS ? (
        <ProductCarouselNavigation
          data={data}
          active={sceneActiveIndex}
          onBackward={previousVideoHandler}
          onForward={nextVideoHandler}
        />
      ) : null}
    </>
  );
};
