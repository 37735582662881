import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { LeftControlSquare, RightControlSquare } from "../../partials/Icons";
import { IProductVideo, IProductMobileImage } from "./types";

function supportsVideoType(type) {
  let video;

  // Allow user to create shortcuts, i.e. just "webm"
  let formats = {
    ogg: 'video/ogg; codecs="theora"',
    h264: 'video/mp4; codecs="avc1.42E01E"',
    webm: 'video/webm; codecs="vp8, vorbis"',
    vp9: 'video/webm; codecs="vp9"',
    hls: 'application/x-mpegURL; codecs="avc1.42E01E"'
  };

  if(!video) {
    video = document.createElement('video')
  }

  return video.canPlayType(formats[type] || type);
}

const VideoDots = ({
  dotIndex,
  activeIndex,
  onClickHandle,
}: {
  dotIndex: number;
  activeIndex: number;
  onClickHandle: (v: number) => void;
}) => {
  const activeClass = dotIndex === activeIndex ? "is-active" : "";

  return (
    <li
      className={`catalog-navigation__item ${activeClass}`}
      onClick={() => onClickHandle(dotIndex)}
    />
  );
};

enum Direction {
  Backward = "backward",
  Forward = "forward",
}

export const CatalogVideo = ({ data, mobileImage }: { data: IProductVideo[], mobileImage: string }) => {
  const [isScreenVisible, setScreenVisible] = useState<boolean>(false);
  const [sceneActiveIndex, setSceneActiveIndex] = useState<number>(0);
  const [sceneActiveSource, setSceneActiveSource] = useState<string>("");

  const [direction, setDirection] = useState<Direction>(Direction.Forward);
  const isForward = direction === Direction.Forward;
  const isSupportVideo = supportsVideoType('webm');

  const videosForward = useMemo(() => {
    return data.map((v) => v.video);
  }, [data]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const captureHandler = useCallback(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (!canvas || !video) return;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext("2d")
      ?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  }, [canvasRef, videoRef]);

  const playVideo = useCallback(() => {
    if (!videoRef.current) return;

    setScreenVisible(true);
    captureHandler();
    videoRef.current.load();

    videoRef.current.onloadeddata = () => {
      if (!videoRef.current) return;
      videoRef.current.play();
      videoRef.current.onplaying = () => {
        setTimeout(() => setScreenVisible(false), 50);
        if (!videoRef.current) return;
        videoRef.current.onplaying = null;
      };
      // videoRef.current.ontimeupdate = () => {
      //   setScreenVisible(false)
      //   if (!videoRef.current) return
      //   videoRef.current.ontimeupdate = null
      // }
    };
  }, [videoRef, captureHandler]);

  const nextVideoHandler = useCallback(
    (index: number) => {
      if (!isForward) {
        setSceneActiveIndex(index - 1);
        setSceneActiveSource(data[index - 1].video);
      } else {
        setSceneActiveIndex(index);
        setSceneActiveSource(data[index].video);
      }

      setDirection(Direction.Forward);
      playVideo();
    },
    [data, isForward, playVideo]
  );

  const previousVideoHandler = useCallback(
    (index: number) => {
      if (!isForward) {
        setSceneActiveIndex(index - 1);
        setSceneActiveSource(data[index - 1].back_video);
      } else {
        setDirection(Direction.Backward);
        setSceneActiveSource(data[index].back_video);
      }

      playVideo();
    },
    [data, isForward, playVideo]
  );

  const jumpHandler = useCallback(
    (index: number) => {
      setSceneActiveIndex(index);
      setSceneActiveSource(data[index].video);
      setDirection(Direction.Forward);
      playVideo();
    },
    [data, playVideo]
  );

  useEffect(() => {
    setSceneActiveSource(data[0].video);
    nextVideoHandler(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={`catalog-screen ${ isSupportVideo ? '' : 'catalog-screen-img'}`}>
      <canvas
        ref={canvasRef}
        className={`catalog-folding-screen`}
        style={{
          opacity: isScreenVisible ? 1 : 0,
        }}
      />
      {!isSupportVideo && mobileImage && <img src={mobileImage} className="catalog-player catalog-player-img" />}
      {isSupportVideo &&
        <video ref={videoRef} className="catalog-player" muted autoPlay>
          <source src={sceneActiveSource} />
        </video>}
      {isSupportVideo && <div className="catalog-controls ddd">
        <button
          className="btn-control is-prev"
          onClick={() => previousVideoHandler(sceneActiveIndex)}
          disabled={sceneActiveIndex === 0}
        >
          <LeftControlSquare />
        </button>
        <button
          className="btn-control is-next"
          onClick={() => nextVideoHandler(sceneActiveIndex + 1)}
          disabled={sceneActiveIndex === videosForward.length - 1}
        >
          <RightControlSquare />
        </button>
        <ul className="catalog-navigation">
          {videosForward.map((item, index) => (
            <VideoDots
              activeIndex={sceneActiveIndex}
              // dot={item}
              dotIndex={index}
              onClickHandle={jumpHandler}
              key={item}
            />
          ))}
        </ul>
      </div>}
    </div>
  );
};
