import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class ProductList extends Component {
  renderItems() {
    return this.props.products.map((item, index) => {
      const img = this.props.media.filter(el => el.attributes.product_category_id.toString() === item.id);

      return <ProductItem
        link={item.attributes.slug}
        image={img.length > 0 ? img[0].attributes.image : ''}
        name={item.attributes.name}
        key={index}
      />
    });
  }

  render() {
    const products = this.renderItems();

    return (
      <div className='products-wrap'>
        {products}
      </div>
    )
  }
}

const ProductItem = (props) => {
  const imageTemplate = (img) => {
    return <React.Fragment>
      <img src={img} className='production-hover' alt={props.name}/>
      <img src={img} className='production-img' alt={props.name}/>
    </React.Fragment>
  }

  return (
    <Link to={`/products/${props.link}`} className='production-link'>
      {props.image && <img src={props.image} className='production-img' alt={props.name}/>}
      <span className='production-title'>{props.name}</span>
    </Link>
  )
}
