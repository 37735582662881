import React, { Component } from "react";

const imagesContext = require.context('../../../../media', true);

export default class CatalogSlide extends Component {
  renderSlides() {
    return this.props.slides.map((item, index) => {
      const activeClass = index === this.props.active ? 'is-active' : '';
      const src = imagesContext(item.src);

      return <div className={`catalog-carousel-slide j-catalog-slide ${activeClass}`} key={index}>
              <div className="catalog-carousel-slide__wrap">
                <div className="catalog-carousel-slide__img">
                  <img src={src}/>
                </div>
              </div>
            </div>
    });
  }

  render() {
    return(
      <div className="catalog-slideshow">
        {this.renderSlides()}
      </div>
    );
  }
}
