import React from "react";
import { Link } from "react-router-dom";
import nextId from "react-id-generator";

import { Close, Configurator, Logo } from "../../partials/Icons";
import { closeResultModal, openConfigurator } from "./ConfiguratorAnimations";
import {
  getSerial,
} from "../../partials/ConfiguratorParts";

// @ts-ignore
export const ModalResult = (props) => {
  // @ts-ignore
  const renderOptions = (item, index) => {
    return (
      <tr key={`resultItem-${index}`}>
        <td>
          {/* <p className="configurator-result__option-label">Обозначение</p> */}
          <span>{item.attributes.code}</span>
        </td>
        <td>
          {/* <p className="configurator-result__option-label">Количество</p> */}
          <span>1</span>
        </td>
        <td>
          {/* <p className="configurator-result__option-label">Название номенклатуры</p> */}
          <span>{item.attributes.name}</span>
          <br />
          {item.attributes.description && (
            <div
              dangerouslySetInnerHTML={{ __html: item.attributes.description }}
            />
          )}
        </td>
      </tr>
    );
  };

  const Prices = () => {
    if (parseFloat(String(props.data.model.price).replace(',', '.')) === 0) {
      return (
        <div className="configurator-result__prices">
          <p>Для уточнения стоимости свяжитесь с вашим менеджером</p>
        </div>
      );
    }

    return (
      <div className="configurator-result__prices">
        <p>Итоговая стоимость (без НДС): {props.data.model.price}</p>
        <p>Итоговая стоимость (с НДС): {props.data.model.price_with_tax}</p>
      </div>
    );
  }

  const ModelImage = () => {
    const imagePath = props.data.model.image;

    if (!imagePath) {
      return <></>
    }

    return (
      <div className="configurator-result__image">
        <img src={imagePath} />
      </div>
    )
  }

  return (
    <div id="resultModal" className="configurator-result">
      <div className="configurator-header navbar-theme-colored">
        <div className="configurator-header-wrap hide-elements">
          <Link to="/">
            <Logo />
          </Link>
          <button
            className="btn"
            onClick={() => {
              closeResultModal("#resultModal");
              props.onResetConfiguratorHandler();
            }}
          >
            <Close />
          </button>
        </div>
      </div>
      <div className="configurator-left-column">
        <button
          className="btn configurator-hint"
          onClick={() => {
            openConfigurator();
            closeResultModal("#resultModal");
          }}
        >
          Вернуться в конфигуратор
        </button>
      </div>
      <div className="configurator-right-column">
        <h3 className="configurator-title">
          <span className="configurator-title__span">
            <Configurator />
            Результат конфигурирования
          </span>
        </h3>
        <div className="configurator-result__inputs-container">
          <div className="configurator-result__title-wrap">
            <div className="configurator-result__title">
              {getSerial(props.data)}
            </div>
            <button
              className="btn"
              onClick={() =>
                props.onAddOrderHandler({
                  id: nextId(),
                  groups: props.data.groups,
                  codes: props.data.codes,
                  resultItemsList: props.data.resultItemsList,
                })
              }
            >
              Добавить в заявку
            </button>
          </div>
          <ModelImage />
          <Prices />
          <table className="configurator-table">
            <tbody>
              <tr>
                <th>Обозначение</th>
                <th>Количество</th>
                <th>Название номенклатуры</th>
              </tr>
              {props.data.resultItemsList.length
                ? props.data.resultItemsList.map((item: any, index: number) =>
                    renderOptions(item, index)
                  )
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
