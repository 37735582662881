import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Menu() {
  const { t } = useTranslation();

  return (
    <nav className="footer-menu">
      <Link className="footer-menu__item" to="/products">{t('Products')}</Link>
      <Link className="footer-menu__item" to="/about">{t('About')}</Link>
      <Link className="footer-menu__item" to="/career">{t('Career')}</Link>
      <Link className="footer-menu__item" to="/news">{t('News')}</Link>
      <Link className="footer-menu__item" to="/reviews">{t('Reviews')}</Link>
      <Link className="footer-menu__item" to="/contacts">{t('Contacts')}</Link>
    </nav>
  )
}
