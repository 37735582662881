import api from "./index";

export const getProductsRelated = async (url) => {
  const path = url.split("/").slice(-1)[0];

  try {
    const response = await api.get(path);
    return response.data.data;
  } catch (e) {
    console.error(e);
  }
};
