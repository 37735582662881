import React, { Component } from "react";

import api from "../../../api";

import { withTranslation } from "react-i18next";

import Footer from "../../partials/footer/Footer";
import Navbar from "../../partials/navbar/Navbar";

class Models extends Component {
  state = {
    data: [],
    query: "",
  };

  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const response = await api.get("/product_models");

    const data = response.data.data;

    this.setState({ data });
  }

  handleSearch(event) {
    const query = event.target.value;

    this.setState({ query });
  }

  withFilterModel(query) {
    if (query === "") {
      return this.state.data;
    }

    return this.state.data.filter((item) => {
      return (
        item.attributes.code.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
        item.attributes.description.toUpperCase().indexOf(query.toUpperCase()) >
          -1 ||
        item.attributes.product_type
          .toUpperCase()
          .indexOf(query.toUpperCase()) > -1
      );
    });
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Navbar />
        <main className="main__theme-blue">
          <section className="models-section">
            <ul className="breadcrumbs is-absolute">
              <li>
                <a href="/">{t("Main page")}</a>
              </li>
              <li>{t("DownloadProductModelsTitle")}</li>
            </ul>

            <section className="models-container section_theme_padding-top">
              <h1 className="reviews-title j-text-appear">
                <span className="text-appear-wrap">
                  {/* <span className='text-appear'> */}
                  <span>{t("DownloadProductModelsTitle")}</span>
                </span>
              </h1>

              <div className="models-table">
                <input
                  type="text"
                  className="models-table-search"
                  onKeyUp={this.handleSearch}
                  placeholder={t("Search for models")}
                />
                <div className="models-table--scroll-inner">
                  <table>
                    <thead>
                      <tr>
                        <th>{t("ModelCode")}</th>
                        <th>{t("ModelName")}</th>
                        <th>{t("ModelProductType")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.withFilterModel(this.state.query).map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>{item.attributes.code}</td>
                            <td>{item.attributes.description}</td>
                            <td>{item.attributes.product_type}</td>
                            <td>
                              <a
                                className="product-models-download-link"
                                href={item.attributes.file}
                                download
                              >
                                {t("ModelDownload")}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </section>
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(Models);
