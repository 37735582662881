import React from "react";
import Bowser from "bowser";
import { withTranslation, WithTranslation } from "react-i18next";

import api from "../../../api";
import Footer from "../../partials/footer/Footer";
import Navbar from "../../partials/navbar/Navbar";
import Loader from "../../partials/Loader";
import ClientsBlock from "./Clients";
import PlusLink from "./PlusLink";

import img1 from "../../../../media/home/hero-closed.jpg";
import img2 from "../../../../media/home/hero-opened.jpg";
import { heroLinkConfig } from "./links";
import { HomeAboutUs } from "./HomeAboutUs";
import { getProducts } from "../products/index";
import { Link } from "react-router-dom";

const HEIGHT_COEFF = 0.6;
const isDesktopPlatform = Bowser.getParser(window.navigator.userAgent).getPlatform().type === "desktop"

const getClients = () => api.get("/our_clients");

const getAdvantages = async () => {
  const response = await api.get("/advantages");
  return response.data.data;
};

const clamp = (value, min = 0, max = 1) => Math.max(min, Math.min(value, max));
const getPositionStyleProps = (x = 0, y = 0) => ({
  style: { left: `${x}%`, top: `${y}%` },
});
const getWindowHeight = (coeff = 1) =>
  (window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight) * coeff;

interface Props extends WithTranslation {
  t: any;
}

class ContentHome extends React.PureComponent<Props> {
  state = {
    imgs: [],
    progress: 0,
    advantages: [],
    products: []
  };

  async setImgs() {
    const clients = await getClients();
    const imgsHandler = ({ attributes: { logo, name } }) => ({
      src: logo,
      text: name,
    });
    const imgs = clients.data.data.map(imgsHandler);

    this.setState({ imgs });
  }

  async setAdvantages() {
    const advantages = await getAdvantages();

    this.setState({ advantages });
  }

  async setProducts() {
    let products = await getProducts();

    products = products.data.data.filter((el) => {
      return el.attributes.category_type === 'category';
    });

    this.setState({ products });
  }

  onScroll = () => {
    const progress = Math.max(
      0,
      Math.min(window.scrollY / getWindowHeight(HEIGHT_COEFF), 1)
    );
    this.setState({ progress });
  };

  componentDidMount() {
    this.setImgs();
    this.setAdvantages();
    this.setProducts();

    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  getLocale() {
    return window.localStorage.getItem("locale") || "ru";
  }

  renderLinks = (config) =>
    config.map(({ x, y, ru, en, ...rest }, index) => (
      <PlusLink
        key={index}
        className="hero-link"
        {...getPositionStyleProps(x, y)}
        {...rest}
      >
        {this.getLocale() === "ru" ? ru : en}
      </PlusLink>
    ));

  links = {
    closed: this.renderLinks(heroLinkConfig.closed),
    opened: this.renderLinks(heroLinkConfig.opened),
  };

  renderMobileLinks(products) {
    let links = products.map((item, index) => {
      return(
        <Link
          to={`/products/${item.attributes.slug}`}
          className='hero-link plus-link'
          key={index}
        >
          <span>{item.attributes.name}</span>
        </Link>
      )
    })

    return links;
  }
  getRevealStyleProps = (transitionPoint = 0, index = 1) => {
    const passedTransitionPoint = this.state.progress >= transitionPoint;

    return {
      style: {
        opacity: passedTransitionPoint ? 1 : 0,
        zIndex: index,
        pointerEvents: passedTransitionPoint ? "all" : "none",
      },
    };
  };

  renderOpened() {
    return(
      <>
        <div
          className="hero-image"
          {...(this.getRevealStyleProps(0.01) as any)}
        >
          <img src={img2} alt="Продукция" />
        </div>

        <div
          className="hero-link-map"
          {...(this.getRevealStyleProps(0.01) as any)}
        >
          {this.links.opened}
        </div>
      </>
    )
  }

  render() {
    const { t } = this.props;

    if (this.state.products.length === 0) return <Loader />;

    const heroLinks = isDesktopPlatform ? this.links.closed : this.renderMobileLinks(this.state.products);

    return (
      <React.Fragment>
        <Navbar transparent={true} />
        <main>
          <div
            className="hero-wrap"
            style={{ height: `${200 * HEIGHT_COEFF}vh` }}
          >
            <section className="hero">
              <div className="hero-image">
                <img src={img1} alt="Продукция" />
              </div>

              <div className="hero-link-map">
                <div className="hero-text">{t("Products")}</div>
                  {heroLinks}
              </div>

              <div className="hero-arrow-down">
                <span></span>
                <span></span>
                <span></span>
              </div>

              {isDesktopPlatform && this.renderOpened()}
            </section>
          </div>

          <HomeAboutUs advantages={this.state.advantages} />
          <ClientsBlock imgs={this.state.imgs} />
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withTranslation()(ContentHome);
