import React, { PureComponent, useState } from "react"
import SlideAnimation from "./SlideAnimation"
import { ProductCarouselNavigation } from './ProductCarouselNavigation'
import { useTranslation } from 'react-i18next';

export default class ProductCarousel extends PureComponent {
  state = {
    slidesTotal: null,
    slidesArray: [],
    active: 0,
    isVideo: false,
  }

  async componentDidMount() {
    const checkVideo = this.props.images.some(s => s.src.includes('mp4'))

    await this.setState({
      slidesTotal: this.props.images.length,
      slidesArray: this.props.images,
      active: this.props.activeImageIndex,
      isVideo: checkVideo,
    })

    setTimeout(() => {
      const nodes = document.querySelectorAll('.j-product-slide')

      this.slides = [];

      [].map.call(nodes, (node) => {
        this.slides.push(new SlideAnimation(node, {}))
      })
    }, 300)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.active !== this.state.active) {
      if (this.state.isVideo) {
        return console.log(this.state.slidesArray)
      }

      return this.navigate('right', prevState)
    }
  }

  navigate(direction, previous) {
    if (this.isAnimating) return
    this.isAnimating = true

    Promise.all([this.slides[previous.active].hide(direction), this.slides[this.state.active].show(direction)])
      .then(() => {
        this.isAnimating = false
      })
      .catch((e) => {
        console.error(e)
      })
  }

  handleActiveUpdate = (value) => {
    this.setState({ active: value })
  }

  handleModal = (imageIndex) => {
    this.props.openModal(imageIndex, this.props.images)
  }

  render() {
    return (
      <div className="product-carousel-section">
        <div className="product-carousel">
          <ProductSlide
            slides={this.props.images}
            active={this.state.active}
            callModal={this.handleModal}
            productName={this.props.productName}
          />
          {
            this.props.images.length > 1 ?
              <ProductCarouselNavigation
                slides={this.props.images}
                active={this.state.active}
                updateActive={this.handleActiveUpdate}
              /> :
              null
          }
        </div>
      </div>
    )
  }
}

export const ProductSlide = ({ slides, active, callModal, productName }) => {
  const { t } = useTranslation();

  const renderSlides = () => {

    return slides.map((item, index) => {
      const altName = index === 0 ? productName : `${productName}, ${t('photo')} ${index}`
      const activeClass = index === active ? 'is-active' : ''

      return <div className={`product-carousel-slide j-product-slide ${activeClass}`} key={index}>
        <div className="product-carousel-slide__wrap">
          <div className="product-carousel-slide__img">
            <img src={item.src} onClick={() => callModal(index)} alt={altName}/>
          </div>
        </div>
      </div>
    })
  }

  return (
    <div className="product-slideshow">
      {renderSlides()}
    </div>
  )
}
