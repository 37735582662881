import React, { useState } from "react";

import { lang } from "../../../../variables/common";
import { FlagRu, FlagUSA } from "../../Icons";

export default function Lang() {
  const [language, setLanguage] = useState(lang);

  const chooseLang = () => {
    const chosenLang = language === "ru" ? "en" : "ru";

    setLanguage(chosenLang);
    document.querySelector("html")?.setAttribute("lang", chosenLang);
    window.localStorage.setItem("locale", chosenLang);
    setTimeout(() => {
      document.location.reload();
    }, 300);
  };

  return (
    <div className="footer-lang">
      <div className="navbar-locale" onClick={chooseLang}>
        {lang === "ru" ? "EN" : "RU"}
      </div>
      {/* <span>{lang.toUpperCase()}</span> */}
    </div>
  );
}
