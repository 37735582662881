import React, { Component } from 'react';
import AnchorLink from "react-anchor-link-smooth-scroll";
import { withTranslation } from 'react-i18next';

import Accordion from "../../Accordion";
import Footer from "../../partials/footer/Footer";
import Navbar from "../../partials/navbar/Navbar";


class ContentCareer extends Component {
  state = {
    selectedIndex: -2,
    vacancies: [],
    faqs: []
  }

  async componentDidMount() {
    await this.getData();
  }

  getData = () => {
    fetch('/api/v1/career_posts', {
      headers: {
        "Locale": window.localStorage.getItem('locale') || "ru"
      }
    })
      .then(result => {
        return result.json()
      })
      .then(res => {
        const vacancies = res.data.map(fact => fact.attributes);

        this.setState({ vacancies })
      })
      .catch(error => console.error(error));
    fetch('/api/v1/faqs', {
      headers: {
        "Locale": window.localStorage.getItem('locale') || "ru"
      }
    })
      .then(result => {
        return result.json()
      })
      .then(res => {
        const faqs = res.data.map(doc => doc.attributes);

        this.setState({ faqs })
      })
      .catch(error => console.error(error));
  }

  renderVacancies = () => {
    return this.state.vacancies.map((vacancy, index) => {
      return <React.Fragment key={`vacancy-${index}`}>
        <p className="career-subtitle">{vacancy.name}</p>
        <div className="career-text" dangerouslySetInnerHTML={{ __html: vacancy.description }}/>
      </React.Fragment>
    })
  }

  renderQuestion = () => {
    return this.state.faqs.map((question, index) => {
      return <div
        data-header={question.question}
        className="accordion-content"
        key={`question-${index}`}>
        <p>{question.answer}</p>
      </div>
    })
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Navbar/>
        <main className="main__theme-blue">
          <section className="career-section">
            <ul className="breadcrumbs is-absolute">
              <li><a href="/">{t('Main page')}</a></li>
              <li>{t('Career')}</li>
            </ul>

            <section className="career-navigation">
              <nav className="anchors-list">
                <AnchorLink
                  href="#anchorFAQ"
                  offset="150"
                  className="anchor-link j-anchor-link"
                >
                  {t('FAQ')}
                </AnchorLink>
              </nav>
            </section>

            <section className="career-container section_theme_padding-top">
              <h1 className="career-title j-text-appear">
                <span className="text-appear-wrap">
                  <span className="text-appear">{t('Co career')}</span>
                </span>
              </h1>
              <p className="career-anons">{t('Opportunities')}.</p>

              {this.renderVacancies()}

              <p className="indent_theme_mb-100">
                <a
                  href="https://spb.hh.ru/employer/47545#vacancy-list"
                  className="career-content-link"
                  target="_blank"
                >
                  {t('Current vacancies')}
                </a>
                <a href="mailto:job@elteh.ru" className="career-content-link" target="_blank">
                  {t('Send resume')}
                </a>
              </p>
              <h2 id="anchorFAQ" className="h2_theme_career j-text-appear">
                <span className="text-appear-wrap">
                  <span className="text-appear">{t('FAQ')}</span>
                </span>
              </h2>
              <Accordion
                className="accordion"
                selectedIndex={this.state.selectedIndex}>
                {this.renderQuestion()}
              </Accordion>
            </section>
          </section>
        </main>
        <Footer/>
      </React.Fragment>
    )
  }
}

export default withTranslation()(ContentCareer);
