import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import api from "../../../api";
import { getProductsRelated } from "../../../api/catalog";
import Navbar from "../../partials/navbar/Navbar";
import { lang } from "../../../variables/common";
import { CatalogVideo } from "./CatalogVideo";
import SubCategories from "./SubCategories";

export const getCategoriesProducts = () =>
  api.get("/product_categories?include=attachments");

export const Catalog = () => {
  const [data, setData] = useState<any>([]);
  const [media, setMedia] = useState([]);
  const [activeCategory, setActiveCategory] = useState<any>({
    attributes: { name: "" },
  });
  const [subcategories, setSubcategories] = useState([]);
  const [activeSubcategoryIndex, setActiveSubcategoryIndex] = useState(0);
  const [catProducts, setCatProducts] = useState([]);
  const [video, setVideo] = useState([]);
  const [image, setImage] = useState<any>(null);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const path = window.location.pathname.split("/");

      const response = await getCategoriesProducts();

      // console.log("Categories products::");

      const media = response.data.included
        ? response.data.included.map((el) => {
            return el.attributes;
          })
        : [];
      let category = response.data.data.filter((el) => {
        return el.attributes.slug === path[path.length - 1];
      });

      setData(response.data.data);
      setMedia(media);
      setActiveCategory(category[0]);
    })();
  }, []);

  useEffect(() => {
    const allSubcategories = data.filter(
      (item: any) => item.attributes.category_type === "subcategory"
    );
    const videos = media.filter((el: any) => el.video);

    const filteredSubcategories = allSubcategories.filter(
      (subcategory: any) =>
        subcategory.attributes.parent_id === activeCategory.id
    );
    const activeSubcategoryVideo =
      videos.length && filteredSubcategories.length
        ? videos.filter(
            (el: any) =>
              el.product_category_id.toString() ===
              filteredSubcategories[activeSubcategoryIndex].id
          )
        : [];
    const activeSubcategoryImage = !!filteredSubcategories.length
      ? media.filter(
          (el: any) =>
            el.image &&
            el.product_category_id.toString() ===
              filteredSubcategories[activeSubcategoryIndex].id
        )
      : [];

    setSubcategories(filteredSubcategories);
    setVideo(activeSubcategoryVideo);
    setImage(activeSubcategoryImage[0]);
  }, [data, activeCategory, activeSubcategoryIndex]);

  async function getProducts(url) {
    // console.log("Get products::url::", url);
    const resp = await getProductsRelated(url);
    // console.log("Get products::resp::", resp);
    resp && setCatProducts(resp);
  }

  useEffect(() => {
    const url = `${activeCategory.relationships?.products.links.related}`;
    getProducts(url);
  }, [activeCategory]);

  const renderCatalogLinks = () => {
    // const catalogLinks = data.filter((item: any) => {
    //   return item.attributes.category_type === "category";
    // });

    // return catalogLinks.map((link: any, i) => {
    //   const isActive = link.attributes.name === activeCategory.attributes.name;

    //   const activeClass = isActive ? "is-active" : "";

    //   return (
    //     <React.Fragment key={`catalog-link-${link.id}`}>
    //       <Link
    //         to={`/products/${link.attributes.slug}`}
    //         className={`catalog-link ${activeClass}`}
    //         key={`catalog-link-${link.id}`}
    //       >
    //         {link.attributes.name}
    //       </Link>
    //       {isActive && renderProductsWithoutSubcategory(catProducts)}
    //       {isActive && subcategories.map(renderSubcategory)}
    //     </React.Fragment>
    //   );
    // });

    return <>{subcategories.map(renderSubcategory)}</>;
  };

  const renderSubcategory = (subcategory, index) => {
    // console.log("Render subcategory::", subcategory);
    const activeClass = index === activeSubcategoryIndex ? "is-active" : "";

    return (
      <div
        className={`catalog-category ${activeClass}`}
        key={`subcategory-${index}`}
      >
        <span
          className="category-title"
          onClick={() => setActiveSubcategoryIndex(index)}
        >
          {subcategory.attributes.name}
        </span>
        <ul className="subcategory-list">
          {/* {console.log("Render subcategory::name", subcategory.attributes.name)} */}
          {/* {console.log("Render subcategory::", subcategory)} */}
          <SubCategories
            subcategory={subcategory}
            category={subcategory.attributes.slug}
            key={`product-link-${index}\``}
          />
        </ul>
      </div>
    );
  };

  const renderProductsWithoutSubcategory = (products) => {
    // console.log("Render products::", products);
    if (products.length === 0) return;

    return (
      <ul className="category-list">
        {products.map((item) => {
          return (
            <li key={`product-list-item-${item.id}`}>
              <Link
                to={`/products/${activeCategory.attributes.slug}/${item.attributes.slug}`}
                className="category-link"
              >
                {item.attributes.name}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <Navbar transparent={true} />
      <main>
        <section className="catalog-section">
          <ul className="breadcrumbs">
            <li>
              <a href="/">{t("Main page")}</a>
            </li>
            <li>
              <a href="/products">{t("Products")}</a>
            </li>
            <li>{activeCategory.attributes.name || ""}</li>
          </ul>
          <div className="catalog-category-wrapper">
            <div className="catalog-links">
              <span className="catalog-links-title">{t("Products")}:</span>
              <nav className="catalog-links-list">{renderCatalogLinks()}</nav>
            </div>

            {/* SLider */}
            <div className="catalog-container">
              <h1 className="catalog-title j-text-appear">
                <span className="text-appear-wrap">
                  <span className="text-appear">
                    {activeCategory.attributes.name || ""}
                  </span>
                </span>
              </h1>

              <div className="catalog-category-wrap"></div>

              {video.length > 0 && (
                <CatalogVideo
                  data={video}
                  mobileImage={activeCategory.attributes.mobile_image}
                />
              )}
              {!video.length && image && (
                <div className="catalog-bg-container">
                  <img
                    className="catalog-bg-image"
                    src={image.image}
                    alt={activeCategory.attributes.name || ""}
                  />
                </div>
              )}
            </div>
          </div>
          {/* { If this weird block is here, NavBar component will change it's style to inverted } */}
          {/* <div className="j-menu-inverse" /> */}
        </section>
      </main>
    </>
  );
};
