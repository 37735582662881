import React, { useState } from "react";

import { FlagRu, FlagUSA } from "../../Icons";
import { lang } from "../../../../variables/common";
import { setCookie } from "../../../../utils/cookie";

const LANGS = {
  en: "RU",
  ru: "EN",
};

export const HeaderContacts = () => {
  const [language, setLanguage] = useState(lang);

  const chooseLang = () => {
    const chosenLang = language === "ru" ? "en" : "ru";

    setLanguage(chosenLang);
    document.querySelector("html")?.setAttribute("lang", chosenLang);
    setCookie('locale', chosenLang);

    setTimeout(() => {
      document.location.reload();
    }, 300);
  };

  return (
    <div className="navbar-contacts-wrap">
      <a href="tel:+78123299797" className="navbar-link navbar-phone">
        <span className="navbar-link-span">+7 (812) 329 97 97</span>
      </a>
      <div className="navbar-email">
        <button className="navbar-locale__icon" onClick={chooseLang}>
          {LANGS[language]}
        </button>
        <a href="mailto:info@elteh.ru" className="navbar-link">
          <span className="navbar-link-span">info@elteh.ru</span>
        </a>
      </div>
    </div>
  );
};
