export default class SplitText {
  getCssProperty(element, property) {
    return window.getComputedStyle( element, null ).getPropertyValue(property);
  }

  getElementFont(element) {
    let font = "";

    font += this.getCssProperty(element, 'font-style');
    font += ` ${this.getCssProperty(element, 'font-variant')}`;
    font += ` ${this.getCssProperty(element, 'font-weight')}`;
    font += ` ${this.getCssProperty(element, 'font-size')}`;
    font += ` ${this.getCssProperty(element, 'font-family')}`;

    return font;
  }

  splitTextByActualLineWidth(textElement, text, width) {
    let result = [];
    let fakeCanvas = document.createElement('canvas');
    let fakeContext = fakeCanvas.getContext("2d");
    let words = text.split(" ");
    let buffer = "";
    fakeContext.font = this.getElementFont(textElement);

    for (let i = 0; i < words.length; i++) {
      let lineWidth = fakeContext.measureText(buffer + words[i]).width;


      if (lineWidth >= width) {
        result.push(buffer + words[i]);
        buffer = "";
      } else {
        buffer += words[i] + " ";
      }
    }

    if (buffer.length)
      result.push(buffer);

    return result;
  }
}
