import React from "react";
import { Category } from "../../../types";
import Products from "./Products";

interface CategoryProps {
  category: Category;
}

const CategoryContent = (props: CategoryProps) => {
  const { category } = props;
  return (
    <>
      <div className="documents-category">
        <div className="documents-category__header">
          {category.attributes.name}
        </div>
        <Products category={category} />
      </div>
    </>
  );
};

export default CategoryContent;
