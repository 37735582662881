import React, {useState} from "react";
import {Close, LeftControl, RightControl} from "../../partials/Icons";
import {TweenLite} from "gsap";

export const Modal = ({onClose, children}) => {
  return (
    <div className="reviews-modal-wrapper j-reviews-modal">
      <button className="btn reviews-close-btn" onClick={onClose}><Close/></button>

      <div className="reviews-modal-content is-centered">
        {children}
      </div>
    </div>
  )
}

export const ModalImage = ({imgIndex, imgList}) => {
  const [activeSlide, setActiveSlide] = useState(imgIndex);

  const handleActiveUpdate = (index) => {
    TweenLite.to(
      "#popupCap",
      0.6,
      {
        ease: Power3.easeInOut,
        width: "100%",
        onComplete: () => {
          setActiveSlide(index);
          TweenLite.to(
            "#popupCap",
            0.6,
            {
              ease: Power3.easeInOut,
              width: 0
            }
          )
        }
      }
    );
  }

  return (
    <div className="reviews-modal-item">
      <div className="popup-image-container">
        <img src={imgList.length ? imgList[activeSlide].src : ""} className="popup-image"/>
        <div id="popupCap" className="popup-cap"/>
      </div>
      <ModalControls list={imgList} active={activeSlide} activeUpdate={handleActiveUpdate}/>
    </div>
  );
}

export const ModalControls = ({active, list, activeUpdate}) => {
  const display = list.length <= 1 ? {display: "none"} : {};

  return (
    <div className="reviews-modal-controls" style={display}>
      <button
        className="reviews-modal-btn"
        onClick={() => activeUpdate(active - 1)}
        disabled={active <= 0}
      >
        <LeftControl/>
      </button>
      <div className="reviews-modal-counter">
        <span className="is-active">{active + 1}/</span>
        <span>{list.length}</span>
      </div>
      <button
        className="reviews-modal-btn"
        onClick={() => activeUpdate(active + 1)}
        disabled={active === list.length - 1}
      >
        <RightControl/>
      </button>
    </div>
  );
}
