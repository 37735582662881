import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { TweenLite } from "gsap";

import api from "../../../api";
import Modal, { Image } from "./Modal";


const getCertificates = () => api.get('/certificates')

class Licences extends Component {
  state = {
    imgs: [],
    page: 1,
    modalOpen: this.props.opened,
    img: ""
  }

  componentDidMount() {
    const timelineImg = new TimelineMax();

    this.imgAnimation = timelineImg.from(
      "#rectangle",
      0.6,
      {
        delay: 0.7,
        ease: Power3.easeInOut,
        scaleX: 0,
        transformOrigin: "100% 0"
      }
    ).reversed(true);
    this.getData();
  }

  async getData() {
    const response = await getCertificates()
    const images = response.data.data.map(image => image.attributes);

    this.setState({ imgs: images });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      page: this.state.page++
    });

    const data = new FormData(event.target);

    fetch('/json/licences.json', {
      method: 'POST',
      body: data
    })
  }

  renderLicence() {
    return this.state.imgs.map((item, index) => {
      return <div className="licences-logo-container" key={index}>
        <img src={item.image} alt="" className="licences-logo" onClick={this.openModal.bind(this, item.image)}/>
        <p className="licences-text">{item.description}</p>
      </div>
    })
  }

  openModal = (src) => {
    const modalState = this.state.modalOpen;

    this.setState({
      modalOpen: !modalState,
      img: src
    });

    this.imgAnimation.reversed(!this.imgAnimation.reversed());

    TweenLite.to(
      ".j-reviews-modal",
      0.8,
      { xPercent: 100, ease: Power3.easeInOut }
    );
    TweenLite.fromTo(
      ".reviews-close-btn .icon-close",
      0.2,
      { yPercent: -100 },
      { yPercent: 0, delay: 0.8 }
    );
  }

  closeModal = () => {
    const modalState = this.state.modalOpen;

    this.setState({ modalOpen: !modalState });
    TweenLite.to(
      ".j-reviews-modal",
      0.8,
      { delay: 0.7, xPercent: 0, ease: Power3.easeInOut }
    );
    TweenLite.to(
      ".reviews-close-btn .icon-close",
      0.2,
      { yPercent: -100, delay: 0.4 }
    );
    this.imgAnimation.reversed(!this.imgAnimation.reversed());
  }

  render() {
    const { t } = this.props;
    const { modalOpen, img } = this.state;

    return (
      <form className="licences-wrap" onSubmit={this.handleSubmit}>
        <div className="licences-logo-wrap">
          {this.renderLicence()}
          <input type="hidden" name="page" value={this.state.page}/>
        </div>
        <Modal show={modalOpen}
               onClose={this.closeModal.bind(this)}>
          <Image img={img}/>
        </Modal>
        <button className="licences-btn-more">{t('Show more')}</button>
      </form>
    )
  }
}

const TranslatedLicenses = withTranslation()(Licences);

export default function LicenceBlock() {
  const { t } = useTranslation();

  return (
    <section id="anchorLicences" className="licences-section j-anchor-container">
      <h2 className="licences-title j-text-appear">
        <span className="text-appear-wrap">
          <span className="text-appear">{t('Licenses and certificates')}</span>
        </span>
      </h2>
      <TranslatedLicenses/>
    </section>
  )
}
