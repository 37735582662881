import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import api from '../../../api';
import Navbar from "../../partials/navbar/Navbar";
import ProductList from "./ProductList";


export const getProducts = () => api.get('/product_categories?include=attachments,products')

export const ContentProducts = () => {
  const [data, setData] = useState([]);
  const [media, setMedia] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response = await getProducts();
      let data = response.data.data.filter((el) => {
        return el.attributes.category_type === 'category';
      });
      let media = response.data.included.filter(el => el.attributes.image);

      setData(data);
      setMedia(media);
    })();
  }, []);

  return (
    <React.Fragment>
      <Navbar  transparent={true}/>
      <main>
        <section className="products-section">
          <ul className="breadcrumbs">
            <li><Link to="/">{t('Main page')}</Link></li>
            <li>{t('Products')}</li>
          </ul>
          <h1 className="products-title j-text-appear">
              <span className="text-appear-wrap">
                <span className="text-appear">{t('Products')}</span>
              </span>
          </h1>
          <ProductList products={data} media={media}/>
          <div className="j-menu-inverse"/>
        </section>
      </main>
    </React.Fragment>
  )
}
