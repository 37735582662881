import { CHANGE_QUANTITY, CLEAR_CART, REMOVE_CART_ITEM } from "../actions/cart";

const initState = [];

const cart = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_QUANTITY:
      const order = action.payload.order;
      const amount = action.payload.amount;

      if (amount !== undefined) {
        if (amount < 1) {
          return state.filter((item: any) => item.id !== order.id);
        } else {
          return state.map((item: any) => {
            if (item.id === order.id) {
              item.amount = amount;

              return order;
            }

            return item;
          });
        }
      } else {
        const newItem = {...order, codes: {...order.codes}, groups: {...order.groups}};
        newItem.amount = 1;

        return [...state, newItem];
      }
    case REMOVE_CART_ITEM:
      return state.filter((item: any) => item.id !== action.payload.id);
    case CLEAR_CART:
      return (state = action.payload);
    default:
      return state;
  }
};

export default cart;
