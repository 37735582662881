import React, { useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import ContentHome from "../pages/home/index";
import { ContentProducts } from "../pages/products";
import ContentAbout from "../pages/about";
import ContentCareer from "../pages/career";
import ContentNews from "../pages/news/";
import ContentSingleNews from "../pages/news/SingleNews";
import ContentReviews from "../pages/reviews";
import ContentContacts from "../pages/contacts";
import ContentDocuments from "../pages/documents";
import Models from "../pages/models";
import { Catalog } from "../pages/catalog";
import ContentProductCard from "../pages/productCard";
import { PageReveal } from "./layer/AnimationLayer";
import {Page404} from "../pages/errorPages";
import MetaTags from "./MetaTags";
import { lang } from '../../variables/common';

const routesRaw = require('../routes_' + lang + '.json');

const PAGES = {
  '/': ContentHome,
  '/products/*/*': ContentProductCard,
  '/products/*': Catalog,
  '/products': ContentProducts,
  '/about': ContentAbout,
  '/career': ContentCareer,
  '/news/*': ContentSingleNews,
  '/news': ContentNews,
  '/reviews': ContentReviews,
  '/contacts': ContentContacts,
  '/documents': ContentDocuments,
  '/models': Models,
}

function Container({ location, seo_tags }) {
  const pathname = location.pathname

  useEffect(() => {
    setSeoTags();
  }, [pathname]);

  const [seoData, setSeoData] = useState({
    path: "",
    title: "",
    description: "",
    keywords: "",
    h1: "",
    seotext: "",
  });

  const setSeoTags = () => {
    const seoData = seo_tags.find(
      (tags) => tags.path === pathname
    );
    seoData && setSeoData(seoData);
  };

  const handlePageChange = () => {
    document.body.classList.remove("body-fixed");
    window.scrollTo(0, 0);
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }

  return (
    <div>
      <MetaTags tags={seoData} />
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={ location.key }
          timeout={{ enter: 1800, exit: 1800 }}
          classNames="fade"
        >
          <section className="route-section">
            <Switch location={location}>
              {routesRaw.map((route, index) => {
                let contentPage = PAGES[route.path]
                return (
                  <Route exact={route.exact} path={route.path} component={ contentPage } onEnter={handlePageChange()} key={index}/>
                );
              })}

              {<Route component={Page404}/>}
            </Switch>
            <PageReveal />
          </section>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default withRouter(Container);
