import React, { Component } from 'react';
import { ChevronBig } from "../../partials/Icons";
import { TimelineMax } from "gsap/TimelineMax";
import { withTranslation } from 'react-i18next';

const TAG_MAP = new Map([
  ['Все', 'All'],
  ['Мероприятия', 'Events'],
  ['Оборудование', 'Equipment'],
])

class NewsFilter extends Component {
  state = {
    activeType: "Все"
  }

  componentDidMount() {
    const tlmax = new TimelineMax();

    this.containerAnimation = tlmax.staggerTo(
      ".j-filter-container",
      0.3,
      { opacity: 1 }
    ).reversed(true);
    this.props.filterList(this.state.activeType);
  }

  getFilterRef = (node) => {
    this.container = node
  };

  onCheckboxChange = (type) => {
    this.setState({ activeType: type }, function () {
      this.props.filterList(this.state.activeType);
    });
  }

  showFilterItems = () => {
    this.containerAnimation.reversed(!this.containerAnimation.reversed());
    this.container.classList.toggle('is-active');
  }

  render() {
    const { t } = this.props;

    return (
      <div className="news-filter" ref={this.getFilterRef}>
        <span className="news-filter-upper-element">{t('Filter by tag')}:</span>
        <button className="news-filter-btn news-filter-upper-element" onClick={this.showFilterItems}>
          {t(TAG_MAP.get(this.state.activeType))}
          <ChevronBig/>
        </button>
        <div className="news-filter-limiter j-filter-container">
          <div className="news-filter-container">
            <ul className="news-filter-list">
              <li>
                <input
                  id="typeAll"
                  className="news-filter-input"
                  type="radio"
                  name="newsTypes"
                  onChange={this.onCheckboxChange.bind(this, "Все")}
                  defaultChecked={true}/>
                <label
                  htmlFor="typeAll"
                  className="news-filter-label"
                  onClick={this.showFilterItems}
                >
                  {t('All')}
                </label>
              </li>
              <li>
                <input
                  id="typeEvent"
                  className="news-filter-input"
                  type="radio"
                  name="newsTypes"
                  onChange={this.onCheckboxChange.bind(this, "Мероприятия")}/>
                <label htmlFor="typeEvent" className="news-filter-label"
                       onClick={this.showFilterItems}>{t('Events')}</label>
              </li>
              <li>
                <input
                  id="typeEquipment"
                  className="news-filter-input"
                  type="radio"
                  name="newsTypes"
                  onChange={this.onCheckboxChange.bind(this, "Оборудование")}/>
                <label htmlFor="typeEquipment" className="news-filter-label"
                       onClick={this.showFilterItems}>{t('Equipment')}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(NewsFilter);
