import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { TweenLite, TimelineMax, Power3 } from "gsap";

import { getConfiguratorDataFx } from "../../../redux/actions/configurator";
import api from "../../../api";
import HighlightAnchor from "../../HighlightAnchor";
import {
  ChevronBig,
  Configurator,
  Facebook,
  Instagram,
  Play,
  Vk,
  Doc,
} from "../../partials/Icons";
import Footer from "../../partials/footer/Footer";
import Navbar from "../../partials/navbar/Navbar";
import { Modal, ModalImage } from "./Modal";
import ProductConfigurator from "./ProductConfigurator";
import ProductCarousel from "./ProductCarousel";
import { openConfigurator } from "./ConfiguratorAnimations";
import { ProductVideo } from "./ProductVideo";

const getCategories = () => api.get("product_categories");
const getProducts = () =>
  api.get(
    "/products?include=product_category,documents,product_properties,attachments"
  );

const anchors = new HighlightAnchor();
const laptopWidth = 1024;

interface Props extends WithTranslation {
  t: any;
  onGetConfiguratorData: (string) => void;
}

export type Product = {
  id: string;
  name: string;
  short_description: string;
  relationships?: [];
  video_link: string;
  mobile_video_link: string;
  full_description: string;
  product_advantages: string;
  configurator: any;
};

export type Category = {
  id: string;
  attributes?: { name: string; slug: string };
};

export type SubCategory = {};

export type Image = {};

export type Attachment = {
  type: string;
  attributes: {
    name: string;
    product_id: string;
    value: string;
    file: any;
    back_video: any;
    video: any;
  };
};

export type Doc = {
  attributes: {
    meta_type: string;
  };
};

type State = {
  products: Product[];
  currentProduct: Product;
  imagesList: Image[];
  categories: Category[];
  currentCategory: Category;
  currentSubCategory: SubCategory;
  relatedProducts: Product[];
  attachments: Attachment[];
  docs: Doc[];
  anchorFeatures: boolean;
  modalOpen: boolean;
  activeImageIndex: number;
  isFooterShown: string;
  isTableOpened: boolean;
};

class ContentProductCard extends PureComponent<Props, State> {
  timelineIcons;
  footer;
  imgAnimation;
  tableFeatures;
  tableBtn;

  state = {
    products: [],
    modalOpen: false,
    activeImageIndex: 0,
    imagesList: [],
    isFooterShown: "",
    categories: [],
    currentProduct: {
      id: "",
      name: "",
      short_description: "",
      video_link: "",
      mobile_video_link: "",
      full_description: "",
      product_advantages: "",
      configurator: null,
    },
    currentCategory: { id: "", attributes: { name: "", slug: "" } },
    currentSubCategory: {},
    relatedProducts: [],
    attachments: [],
    docs: [],
    isTableOpened: false,
    anchorFeatures: true,
  };

  async componentDidMount() {
    await getCategories().then(({ data }) => {
      const filteredCategories = data.data.filter((el) => {
        return el.attributes.category_type === "category";
      });
      this.setState({ categories: filteredCategories });
    });

    const response = await getProducts();
    const productsHandler = ({
      id,
      attributes: {
        name,
        product_category_id,
        slug,
        short_description,
        full_description,
        product_advantages,
        video_link,
        mobile_video_link,
        configurator,
      },
      relationships,
    }) => ({
      id,
      name,
      product_category_id,
      link: slug,
      short_description,
      full_description,
      product_advantages,
      video_link,
      mobile_video_link,
      configurator,
      relationships,
    });
    const products = response.data.data.map(productsHandler);
    const path = window.location.pathname.split("/").slice(-1)[0];
    const currentProduct = products.filter((item) => item.link === path)[0];
    const relatedProducts = products.filter(
      (item) => item.product_category_id === currentProduct.product_category_id
    );
    const attachments = response.data.included;
    const currentSubCategory = attachments.filter(
      (category) =>
        category.type === "product_categories" &&
        parseInt(category.id) === currentProduct.product_category_id
    );
    const docs = attachments.filter(
      (item) =>
        item.type === "documents" &&
        String(item.attributes.product_id) === currentProduct.id
    );

    const allCategories = this.state.categories as Category[];
    const currentCategory = allCategories.filter(
      (category) => currentSubCategory[0].attributes.parent_id === category.id
    )[0];

    this.setState({
      products,
      currentProduct,
      currentCategory,
      currentSubCategory,
      relatedProducts,
      attachments,
      docs,
    });

    const timelineImg = new TimelineMax();
    this.timelineIcons = new TimelineMax();
    this.footer = document.querySelector(".footer");

    this.imgAnimation = timelineImg
      .to("#popupCap", 0.6, {
        delay: 0.7,
        ease: Power3.easeInOut,
        width: 0,
      })
      .to(".reviews-modal-controls", 0.5, { opacity: 1 })
      .reversed(true);

    if (window.screen.availWidth >= laptopWidth) {
      window.addEventListener("scroll", this.isInViewport);
    }
    anchors.init();
  }

  componentWillUnmount() {
    if (window.screen.availWidth >= laptopWidth) {
      window.removeEventListener("scroll", this.isInViewport);
    }

    anchors.remove();
  }

  getTableRef = (node) => (this.tableFeatures = node);
  getTableBtnRef = (node) => (this.tableBtn = node);
  getAnchorFeaturesRef = () => this.setState({ anchorFeatures: false });

  tableRowRefs: HTMLElement[] = [];

  setTrRef = (ref: HTMLElement) => {
    ref && this.tableRowRefs.push(ref);
    setTimeout(this.setTableHeight, 50);
  };

  toggleList = (event) => {
    event.target.parentElement.classList.toggle("is-active");
  };

  openModal = (index, imagesList) => {
    const modalState = this.state.modalOpen;

    this.setState({
      modalOpen: !modalState,
      activeImageIndex: index,
      imagesList,
    });

    this.imgAnimation.reversed(!this.imgAnimation.reversed());

    TweenLite.to(".j-reviews-modal", 0.8, {
      xPercent: 100,
      ease: Power3.easeInOut,
    });
    TweenLite.fromTo(
      ".reviews-close-btn .icon-close",
      0.2,
      { yPercent: -100 },
      { yPercent: 0, delay: 0.8 }
    );
  };

  closeModal = () => {
    const modalState = this.state.modalOpen;

    this.setState({ modalOpen: !modalState });
    TweenLite.to(".j-reviews-modal", 0.8, {
      delay: 0.7,
      xPercent: 0,
      ease: Power3.easeInOut,
    });
    TweenLite.to(".reviews-close-btn .icon-close", 0.2, {
      yPercent: -100,
      delay: 0.4,
    });
    this.imgAnimation.reversed(!this.imgAnimation.reversed());
  };

  isInViewport = () => {
    if (!this.footer) return false;
    const top = this.footer.getBoundingClientRect().top;

    if (top >= 0 && top <= window.innerHeight) {
      this.setState({ isFooterShown: "is-shown" });
    } else {
      this.setState({ isFooterShown: "" });
    }
  };

  renderFeatures = () => {
    const currentProduct = this.state.currentProduct as Product;
    if (!currentProduct.relationships) return;

    const allAttachments = this.state.attachments as Attachment[];
    const properties = allAttachments.filter(
      (item) =>
        item.type === "product_properties" &&
        item.attributes.product_id === this.state.currentProduct.id
    );

    if (properties.length) {
      return (
        <div className="product-table-features-wrap">
          <h2
            id="anchorFeatures"
            className="product-subtitle j-anchor-container"
            ref={this.getAnchorFeaturesRef}
          >
            {this.props.t("Electrical data")}:
          </h2>
          <ul className="product-table-features" ref={this.getTableRef}>
            {properties.map((item, index) => {
              return (
                <li
                  className="product-table-features__item"
                  key={`property-${index}`}
                  ref={this.setTrRef as any}
                >
                  <span className="product-table-features__item-name">
                    {item.attributes.name}
                  </span>
                  <span className="product-table-features__item-value">
                    {item.attributes.value}
                  </span>
                </li>
              );
            })}
          </ul>
          <button
            className="product-table-btn"
            onClick={this.openTable}
            ref={this.getTableBtnRef}
          >
            {this.props.t("Show all")} <ChevronBig />
          </button>
        </div>
      );
    }
  };

  setTableHeight = () => {
    if (this.tableRowRefs.length > 5 && this.tableFeatures) {
      const tableTopPosition = this.tableFeatures.getBoundingClientRect().top;
      const tableRowTopPosition =
        this.tableRowRefs[5].getBoundingClientRect().top;

      this.tableFeatures.style.maxHeight = `${
        tableRowTopPosition - tableTopPosition
      }px`;
    }
  };

  openConfiguratorHandle = (id) => {
    this.props.onGetConfiguratorData(id);
    openConfigurator();
  };

  openTable = () => {
    const tableTopPosition = this.tableFeatures.getBoundingClientRect().top;
    const tableRowTopPosition =
      this.tableRowRefs[5].getBoundingClientRect().top;
    const tableFullHeight = `${
      this.tableRowRefs[this.tableRowRefs.length - 1].getBoundingClientRect()
        .top
    }px`;

    this.tableFeatures.style.maxHeight = `${
      tableRowTopPosition - tableTopPosition
    }px`;

    if (this.tableFeatures.style.maxHeight !== tableFullHeight) {
      this.tableFeatures.style.maxHeight = tableFullHeight;
      this.tableBtn.classList.add("is-opened");
    } else {
      this.tableFeatures.style.maxHeight = `${
        tableRowTopPosition - tableTopPosition
      }px`;
      this.tableBtn.classList.remove("is-opened");
    }
  };

  renderYoutube = () => {
    if (!this.state.currentProduct.video_link) return;

    const videoUrl = this.state.currentProduct.video_link.split("=")[1];

    return (
      <iframe
        className="product-video-frame product-content-end"
        src={`https://www.youtube-nocookie.com/embed/${videoUrl}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  };

  renderConstruction = () => {
    if (!this.state.currentProduct.full_description) return;

    return (
      <React.Fragment>
        <h2
          id="anchorConstruction"
          className="product-subtitle j-anchor-container"
        >
          {this.props.t("Construction")}:
        </h2>
        <div
          className="product-content-end"
          dangerouslySetInnerHTML={{
            __html: this.state.currentProduct.full_description,
          }}
        />
      </React.Fragment>
    );
  };

  renderAdvantages = () => {
    if (!this.state.currentProduct.product_advantages) return;

    return (
      <React.Fragment>
        <h2
          id="anchorAdvantages"
          className="product-subtitle j-anchor-container"
        >
          {this.props.t("Benefits")}:
        </h2>
        <div
          className="product-content-end"
          dangerouslySetInnerHTML={{
            __html: this.state.currentProduct.product_advantages,
          }}
        />
      </React.Fragment>
    );
  };

  renderCarousel = () => {
    const imagesHandler = ({ attributes: { file } }) => ({ src: file });
    const videosHandler = ({ attributes: { file, back_video } }) => ({
      video: file,
      back_video,
    });

    const allAttachments = this.state.attachments as Attachment[];
    let images = allAttachments.filter((item) => {
      return (
        item.type === "attachments" &&
        item.attributes.product_id === this.state.currentProduct.id
      );
    });

    if (images.length) {
      let checkVideo = images.some((s) => s.attributes.file.includes("mp4"));
      checkVideo = images.some((s) => s.attributes.file.includes("webm"));

      if (checkVideo) {
        const filteredVideos = images.map(videosHandler);

        return <ProductVideo videos={filteredVideos} />;
      }
      const filteredImages = images.map(imagesHandler);

      return (
        <ProductCarousel
          images={filteredImages}
          activeImageIndex={this.state.activeImageIndex}
          openModal={this.openModal}
        />
      );
    }
  };

  renderRelatedAnchors = () => {
    if (this.state.categories.length === 0) return;

    const allCategories = this.state.categories as Category[];

    return allCategories.map(({ attributes }, index) => {
      return (
        <li key={`relate-${index}`}>
          <Link
            to={`/products/${attributes?.slug}`}
            className="anchors-archive-item"
          >
            {attributes?.name}
          </Link>
        </li>
      );
    });
  };

  renderDocs = () => {
    const allDocs = this.state.docs as Doc[];

    const manuals = allDocs.filter(
      (item) => item.attributes.meta_type === "manual"
    );
    const booklets = allDocs.filter(
      (item) => item.attributes.meta_type === "booklet"
    );
    const certificates = allDocs.filter(
      (item) => item.attributes.meta_type === "certificate"
    );
    const questionnaires = allDocs.filter(
      (item) => item.attributes.meta_type === "questionnaire"
    );
    const conclusions = allDocs.filter(
      (item) => item.attributes.meta_type === "permits_and_conclusions"
    );

    return [
      { array: manuals, arrayTitle: this.props.t("Manual") },
      { array: booklets, arrayTitle: this.props.t("Brochures") },
      { array: certificates, arrayTitle: this.props.t("Certificates") },
      { array: questionnaires, arrayTitle: this.props.t("Questionnaire") },
      { array: conclusions, arrayTitle: this.props.t("Conclusions") },
    ].map(this.templateDocs);
  };

  templateDocs = (data, indexTemplate) => {
    if (data.array.length === 0) {
      return;
    }

    const itemTemplate = (doc, index) => {
      return (
        <li key={`doc-${index}`}>
          <a
            href={doc.attributes.file}
            className="anchors-archive-item"
            target="_blank"
            download
          >
            <Doc />
            {doc.attributes.name}
          </a>
        </li>
      );
    };

    return (
      <nav className="anchors-list" key={`list-${indexTemplate}`}>
        <span
          className="anchors-archive-title"
          onClick={(event) => this.toggleList(event)}
        >
          {data.arrayTitle}
          <ChevronBig />
        </span>
        <ul className="anchors-archive">{data.array.map(itemTemplate)}</ul>
      </nav>
    );
  };

  renderVideo = () => {
    const allAttachments = this.state.attachments as Attachment[];
    const videos = allAttachments.filter(
      (video) =>
        video.type === "attachments" &&
        video.attributes.product_id === this.state.currentProduct.id
    );

    if (videos.length) {
      return videos.map((item, index) => {
        return (
          <div
            className="product-media-preview media-container"
            key={`video-${index}`}
          >
            <div className="media-container__appear is-white" />
            <div className="media-container__appear is-color" />
            <video className="product-preview" autoPlay>
              <source src={item.attributes.video} />
            </video>
            <button
              className="product-media-btn"
              onClick={this.openModal.bind(
                this,
                item.attributes.video,
                "video"
              )}
            >
              <Play />
            </button>
          </div>
        );
      });
    }
  };

  renderNavbarLink = () => {
    if (this.state.currentCategory) {
      return (
        <Link
          to={`/products/${this.state.currentCategory.attributes.slug || ""}`}
        >
          {this.state.currentCategory.attributes.name || ""}
        </Link>
      );
    } else {
      const currentAttributes = this.state.currentSubCategory[0].attributes;

      return (
        <Link to={`/products/${currentAttributes.slug || ""}`}>
          {currentAttributes.name || ""}
        </Link>
      );
    }
  };

  render() {
    const { t } = this.props;
    const { modalOpen, activeImageIndex, imagesList, currentProduct } =
      this.state;

    return (
      <React.Fragment>
        <Navbar />
        <main className="main__theme-blue">
          {/* <video autoPlay loop muted className="product-background-video">
            <source src={backgroundVideo} type="video/mp4"></source>
          </video> */}
          <section className="product-section section_theme_padding-top">
            <ul className={`breadcrumbs is-absolute`}>
              <li>
                <Link to="/">{t("Main page")}</Link>
              </li>
              <li>
                <Link to="/products">{t("Products")}</Link>
              </li>
              <li>{this.renderNavbarLink()}</li>
            </ul>

            <section className="product-navigation">
              <nav className="anchors-list is-mb-40">
                <AnchorLink
                  href="#anchorFeatures"
                  offset="150"
                  className="anchor-link j-anchor-link is-active"
                  hidden={this.state.anchorFeatures}
                >
                  {t("Electrical data")}
                </AnchorLink>
                <AnchorLink
                  href="#anchorConstruction"
                  offset="150"
                  className="anchor-link j-anchor-link"
                  hidden={!this.state.currentProduct.full_description}
                >
                  {t("Construction")}
                </AnchorLink>
                <AnchorLink
                  href="#anchorAdvantages"
                  offset="150"
                  className="anchor-link j-anchor-link"
                  hidden={!this.state.currentProduct.product_advantages}
                >
                  {t("Benefits")}
                </AnchorLink>
              </nav>
              <nav className="anchors-list">
                <span
                  className="anchors-archive-title"
                  onClick={(event) => this.toggleList(event)}
                >
                  {t("Catalogue")}
                  <ChevronBig />
                </span>
                <ul className="anchors-archive">
                  {this.renderRelatedAnchors()}
                </ul>
              </nav>
              {this.renderDocs()}
              <span className="sidebar-address">{t("Address")}</span>
            </section>

            <section className="product-container">
              <div className="product-supplement-section">
                {this.renderCarousel()}
                <div className="product-supplement-links">
                  <Link to="/reviews" className="product-supplement-link">
                    {t("Show reviews")}
                  </Link>
                  {currentProduct.configurator && (
                    <span
                      className="product-supplement-link"
                      onClick={() =>
                        this.openConfiguratorHandle(currentProduct.configurator)
                      }
                      hidden={!currentProduct.configurator}
                    >
                      <Configurator />
                      {t("Configurator")}
                    </span>
                  )}
                </div>
                <div className={`section-socials ${this.state.isFooterShown}`}>
                  <a href="#" className="social-link">
                    <Instagram /> parseInt(
                  </a>
                  <a href="#" className="social-link">
                    <Facebook />
                  </a>
                  <a href="#" className="social-link">
                    <Vk />
                  </a>
                </div>
              </div>
              <h1 className="product-title j-text-appear">
                <span className="text-appear-wrap">
                  <span className="text-appear">
                    {this.state.currentProduct.name}
                  </span>
                </span>
              </h1>
              <p className="product-announce">
                {this.state.currentProduct.short_description}
              </p>
              {this.renderFeatures()}
              <div className="product-content-wrap">
                {this.renderYoutube()}
                {this.renderConstruction()}
                {this.renderAdvantages()}
                {/*{this.renderVideo()}*/}
                {/*<h2 id="anchorOrder" className="product-subtitle j-anchor-container">Заказ продукции</h2>
                <form action="" className="product-form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="input-default"
                      defaultValue=""
                      placeholder="Имя"
                      required/>
                    <span className="form-require-text">Опционально</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="input-default"
                      defaultValue=""
                      placeholder="E-mail"
                      required/>
                    <span className="form-require-text">Опционально</span>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="input-default"
                      defaultValue=""
                      placeholder="Должность"/>
                    <span className="form-require-text">Опционально</span>
                  </div>
                  <div className="form-group">
                    <InputMask mask="+7(999) 999-99-99"
                               maskChar=" "
                               className="input-default"
                               placeholder="Тел: +7(___)___-__-__"/>
                    <span className="form-require-text">Опционально</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      type="text"
                      className="input-default text-area-default"
                      defaultValue=""
                      placeholder="Сообщение"
                      rows="1"
                      required>
                    </textarea>
                    <span className="form-require-text">Опционально</span>
                  </div>
                  <div className="checkbox-group">
                    <input type="checkbox" id="agreement" className="input-checkbox" name="agreement"/>
                    <span className="input-checkbox-mark"></span>
                    <label htmlFor="agreement" className="checkbox-label">Я даю <a href="#">согласие</a> на обработку
                      персональных данных</label>
                  </div>
                  <button className="submit-btn btn-theme-bg-blue">
                    <span>Отправить</span>
                  </button>
                </form>*/}
              </div>
              {/*<div className="related-products">
                <h2 className="product-subtitle">С этим продуктом вы можете приобрести:</h2>
                <div className="related-products-table">
                  {this.state.relatedProducts.map((item, index) =>
                    <RelativeItems
                      itemName={item.name}
                      category={this.state.currentSubCategory[0]}
                      key={`relateItem-${index}`}/>)
                  }
                </div>
              </div>*/}
            </section>
          </section>
        </main>
        <Modal show={modalOpen} onClose={this.closeModal.bind(this)}>
          <ModalImage imgIndex={activeImageIndex} imgList={imagesList} />
        </Modal>
        {currentProduct.configurator && <ProductConfigurator />}
        <Footer />
      </React.Fragment>
    );
  }
}

const RelativeItems = (props) => {
  return (
    <div className="related-product-item">
      <div className="related-product-item__row">
        <div className="related-product-item__label">Продукт:</div>
        <div className="related-product-item__name">
          <a href="#" className="related-product-link">
            {props.itemName}
          </a>
        </div>
      </div>
      <div className="related-product-item__row">
        <div className="related-product-item__label">Категория:</div>
        <div className="related-product-item__name">
          <a href="#" className="related-product-link">
            {props.category.attributes.name}
          </a>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetConfiguratorData: (id) => {
    dispatch(getConfiguratorDataFx(id));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(ContentProductCard));
