import React, { useEffect, useState } from "react";
import { Product } from "../../../types";
import { Doc } from "../../partials/Icons";
import axios from "axios";

interface DocumentsProps {
  product: Product;
}

const Documents = (props: DocumentsProps): JSX.Element => {
  const { product } = props;
  const [data, setData] = useState<any>([]);

  async function fetchRelationships(product: Product) {
    const path = product.relationships?.documents?.links?.related || "/";
    const res = await axios.get(path, {
      headers: {
        Locale: window.localStorage.getItem("locale") || "ru",
      },
    });
    setData(res.data.data);
  }

  useEffect(() => {
    fetchRelationships(product);
  }, []);

  return (
    <div className="documents-category__documents">
      {data.map((document) => {
        return document ? (
          <div key={document.id} className="documents-category__document">
            <a href={document.attributes.file} target="_blank" download>
              <div className="documents-category__document__header">
                <Doc />
                {document.attributes.name}
              </div>
            </a>
          </div>
        ) : (
          <></>
        );
      })}
    </div>
  );
};

export default Documents;
