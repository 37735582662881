import React, {Component} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {ChevronBig} from "../../partials/Icons";
import NewsFilter from "./NewsFilter";
import {NewsList, NewsHero} from "./NewsList";
import Footer from "../../partials/footer/Footer";
import Navbar from "../../partials/navbar/Navbar";
import { withTranslation } from 'react-i18next';
import { lang } from '../../../variables/common'

const URL = "/api/v1/posts";
const QUANTITY = 4;

class ContentNews extends Component {
  state = {
    data: [],
    filterValue: "Все",
    filterYear: null,
    hasNextPage: true,
    years: []
  }

  componentDidMount() {
    fetch('/api/v1/posts_dates', {
      headers: {
        "Locale": window.localStorage.getItem('locale') || "ru"
      }
    })
      .then(result => result.json())
      .then(res => {
        this.setState({years: res.data})
      })
      .catch(error => console.error(error));
  }

  getListRef = (node) => this.archiveList = node;

  loadItems = (page) => {
    const filteredUrl = this.state.filterYear ?
      `${URL}?page[size]=${QUANTITY}&page[number]=${page}&stats[total]=count&filter[published_at][gte]=${this.state.filterYear}-01-01&filter[published_at][lte]=${this.state.filterYear}-12-31` :
      `${URL}?page[size]=${QUANTITY}&page[number]=${page}&stats[total]=count`;

    fetch(filteredUrl, {
      headers: {
        "Locale": lang
      }
    })
      .then(res => res.json())
      .then(json => {
        const hasNextPage = (page * QUANTITY) < (json.meta.stats.total.count || 0);
        const itemHandler =
          (
            {
              id,
              attributes: {title, link, description, date, type, image, published_at}
            }
          ) => ({
            id, title, link, description, date, type, image, published_at
          });
        const mapedData = json.data.map(itemHandler).filter(item => {
          if (this.state.filterValue === "Все") {
            return item;
          }

          if (item.type === this.state.filterValue) {
            return item;
          }
        });

        if (this.state.filterYear) {
          mapedData.filter(item => {
            const itemYear = item.date.split(".");

            if (itemYear[itemYear.length - 1] === this.state.filterYear) {
              return item;
            }
          });
        }

        this.setState(({data}) => ({data: this.sortNews([...data, ...mapedData]), hasNextPage}));
      })
      .catch(e => console.error(e))
  }

  sortNews(data) {
    const sortedData =  data.sort((a, b) => {
      return Date.parse(a.published_at) < Date.parse(b.published_at);
    })

    return sortedData;
  }

  filterListByTag(prop) {
    this.setState({filterValue: prop.prop});
  }

  filterListByYear(year) {
    this.setState(() => ({data: [], filterYear: year}), () => this.loadItems(1))
  }

  toggleArchive = () => {
    this.archiveList.classList.toggle('is-active');
  }

  renderNewsItem(item, itemIndex) {
    if (itemIndex === 0) {
      return <NewsHero
        title={item.title}
        date={item.date}
        image={item.image}
        type={item.type}
        link={item.link}
        key={itemIndex}/>
    } else {
      return <NewsList
        title={item.title}
        date={item.date}
        image={item.image}
        type={item.type}
        link={item.link}
        key={itemIndex}/>
    }
  }

  renderYearLinks = () => {
    return this.state.years.map(year => {
      return <li key={`year-link-${year}`}>
        <span className="news-archive-item" onClick={() => this.filterListByYear(year)}>
          {year}
        </span>
      </li>
    });
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Navbar/>
        <main className="main__theme-blue">
          <section className="news-section">
            <ul className="breadcrumbs is-absolute">
              <li><a href="/">{t('Main page')}</a></li>
              <li>{t('News')}</li>
            </ul>

            <section className="news-navigation">
              <nav className="anchors-list" ref={this.getListRef}>
                <span className="news-archive-title" onClick={this.toggleArchive}>
                  {t('News archive')} <ChevronBig/>
                </span>
                <ul className="anchors-archive">
                  {this.renderYearLinks()}
                </ul>
              </nav>
            </section>

            <section className="news-container section_theme_padding-top">
              <div className="news-filter-wrap">
                <h1 className="news-title j-text-appear">
                  <span className="text-appear-wrap">
                    <span className="text-appear">{t('News')}</span>
                  </span>
                </h1>
                <NewsFilter
                  filterList={prop => this.filterListByTag({prop})}
                  data={this.state.data}
                />
              </div>
              <InfiniteScroll
                pageStart={0}
                loadMore={(page) => this.loadItems(page)}
                hasMore={this.state.hasNextPage}
                // loader={<ScrollLoader/>}
                threshold={0}
              >
                <div className="news-container__wrap">
                  {this.state.data.map(this.renderNewsItem)}
                </div>
              </InfiniteScroll>
            </section>
          </section>
        </main>
        <Footer/>
      </React.Fragment>
    )
  }
}

export default withTranslation()(ContentNews);
