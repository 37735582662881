import React, { Component, createRef } from 'react';
import { LeftControl, RightControl } from '../../partials/Icons';
import { TimelineMax } from "gsap";
import { withTranslation } from 'react-i18next';

import api from '../../../api';
import CarouselSlide from './CarouselSlide';

export const getAdvantages = () => api.get('/advantages')

class Carousel extends Component {
  state = {
    activeSlideIndex: 0,
    slideList: []
  }

  carouselRef = createRef();
  getCarouselRef = (node) => {
    this.carousel = node
  };
  getBtnLeftRef = (node) => {
    this.btnLeft = node
  };
  getBtnRightRef = (node) => {
    this.btnRight = node
  };

  async componentDidMount() {
    const response = await getAdvantages()
    const slidesHandler = ({ attributes: { name, description } }) => ({ title: name, text: description || "" })
    const slideList = response.data.data.map(slidesHandler)
    this.setState({ slideList });
    this.tlSlide = new TimelineMax();
    this.tlSpans = new TimelineMax();
    this.btnLeft.disabled = true;
    this.width = response.data.data.length ?
      this.carouselRef.current.querySelector('.main-carousel-slide').clientWidth :
      0
    this.slideAnimation = (width) => this.tlSlide.to(this.carouselRef.current, 0.6, {
      x: width,
      ease: Power2.easeInOut
    });
  }

  incrementIndex = () => {
    if (this.state.activeSlideIndex !== (this.state.slideList.length - 1)) {
      this.setState({ activeSlideIndex: this.state.activeSlideIndex + 1 }, () => {
        if (this.state.activeSlideIndex === this.state.slideList.length - 1) {
          this.btnRight.disabled = true;
        } else {
          this.btnLeft.disabled = false;
          this.btnRight.disabled = false;
        }
      });

      this.slideAnimation(`-=${this.width}`);
    }
  }

  decrementIndex = () => {
    if (this.state.activeSlideIndex !== 0) {
      this.setState({ activeSlideIndex: this.state.activeSlideIndex - 1 }, () => {
        if (this.state.activeSlideIndex === 0) {
          this.btnLeft.disabled = true;
        } else {
          this.btnLeft.disabled = false;
          this.btnRight.disabled = false;
        }
      });
      this.slideAnimation(`+=${this.width}`);
    }
  }

  render() {
    const { t } = this.props;

    return (
      <section className="main-carousel-section">
        <h2 className="carousel-title j-text-appear">
          <span className="text-appear-wrap">
            <span className="text-appear">
              {t('Some facts')}
            </span>
          </span>
        </h2>
        <div className="carousel-controls">
          <button className="carousel-btn" ref={this.getBtnLeftRef} onClick={this.decrementIndex}>
            <LeftControl/>
          </button>
          <button className="carousel-btn" ref={this.getBtnRightRef} onClick={this.incrementIndex}>
            <RightControl/>
          </button>
        </div>
        <div className="main-carousel-wrap">
          <div ref={this.carouselRef}>
            <MainpageSlides
              slides={this.state.slideList}
              active={this.state.activeSlideIndex}
              ref={this.getCarouselRef}
            />
          </div>
        </div>
      </section>
    )
  }
}

class MainpageSlides extends Component {
  renderSlides() {
    return this.props.slides.map((slide, index) => {
      const activeClass = index === this.props.active ? 'is-active' : '';

      return <CarouselSlide
        title={slide.title}
        text={slide.text}
        key={index + slide}
        active={activeClass}
      />
    });
  }

  render() {
    const slides = this.renderSlides();

    return (
      <div className="main-carousel">
        {slides}
      </div>
    )
  }
}

export default withTranslation()(Carousel);
