import React, { Component, createRef } from 'react';
import { TweenLite, TimelineMax } from "gsap";
import { withTranslation } from 'react-i18next';

import { Burger, Close, Logo, Loupe, FlagRu, Facebook, Vk, Instagram, FlagUSA } from '../../Icons';
import { showSearchBlock } from '../../search/SearchAnimations';
import { lang } from '../../../../variables/common';
import MenuLinks from './MenuLinks';
import { setCookie } from '../../../../utils/cookie';

class MobileMenu extends Component {
  componentDidMount() {
    const tlmax = new TimelineMax();

    this.menuAnimation = TweenLite.to(
      this.menuRef.current,
      0.8,
      { left: "0", ease: Power3.easeInOut, delay: 0.5 }
    ).reversed(true);

    this.contentAnimation = tlmax.staggerFromTo(
      ".j-mobile-menu-animate",
      0.4,
      { opacity: 0 },
      { opacity: 1, delay: 0.6 }
    ).reversed(true);
  }

  menuRef = createRef();

  toggleDirection = () => {
    this.menuAnimation.reversed(!this.menuAnimation.reversed());
    this.contentAnimation.reversed(!this.contentAnimation.reversed());
    document.body.classList.toggle('body-fixed');
  }

  onSearchClick = () => {
    this.toggleDirection();
    showSearchBlock();
  }

  changeLang = () => {
    const chosenLang = lang === 'ru' ? 'en' : 'ru';

    document.querySelector("html").setAttribute("lang", chosenLang);
    setCookie('locale', chosenLang);
    setTimeout(() => {
      document.location.reload();
    }, 300);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="mobile-menu-wrap">
        <button className="navbar-mobile-btn" onClick={this.toggleDirection}>
          <Burger/>
        </button>
        <div className="mobile-menu-container" ref={this.menuRef}>
          <div className="mobile-menu-icons-wrap j-mobile-menu-animate">
            <Logo/>
            <button className="navbar-mobile-btn__close" onClick={this.toggleDirection}>
              <Close/>
            </button>
          </div>
          <nav className="navbar-menu__mobile j-mobile-menu-animate">
            <MenuLinks className="navbar-menu__mobile-item" startWith={['about']} />
          </nav>
          <div className="mobile-menu-search-wrap j-mobile-menu-animate" onClick={this.onSearchClick}>
            <Loupe/>
            <span className="mobile-menu-search-text">Поиск</span>
          </div>
          <a href="mailto:info@elteh.ru" className="navbar-link__mobile navbar-email__mobile j-mobile-menu-animate">
            info@elteh.ru
          </a>
          <a href="tel:+78123299797" className="navbar-link__mobile navbar-phone__mobile j-mobile-menu-animate">
            +7 (812) 329 97 97
          </a>
          <div className="mobile-menu-footer j-mobile-menu-animate">
            <button className="mobile-menu-footer__locale-btn" onClick={this.changeLang}>
              {lang === 'ru' ? 'EN' : 'RU'}
            </button>
            {/*<span>{lang.toUpperCase()}</span>*/}
            {/* <a href="#" className="mobile-menu-link__social" target="_blank"><Instagram/></a> */}
            {/* <a href="#" className="mobile-menu-link__social" target="_blank"><Facebook/></a> */}
            <a href="#" className="mobile-menu-link__social" target="_blank"><Vk/></a>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(MobileMenu);
