import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { lang } from '../../../../variables/common';

export default function MenuLinks(props) {
  const { t } = useTranslation();

  const sectionNames: string[] = [
    'products',
    'about',
    'career',
    'news',
    'reviews',
    'contacts',
    'documents'
  ];

  const routePaths = require('../../../routes_' + lang + '.json').map(item => item.path);
  const routes = sectionNames.filter(item => routePaths.includes(`/${item}`));

  const pathsWithOut = (names: string[]) => {
    return routes.filter((item: string) => !names.includes(item));
  }

  const changeListSort = (list: any) => {
    const names = Array.isArray(list) ? list : [];

    names.push(pathsWithOut(names));
    return names.flat();
  }

  const capitalize = (word: string) => {
    const firstLetter: string = word.charAt(0);
    return firstLetter.toUpperCase() + word.slice(1);
  }

  const linksList = changeListSort(props.startWith).map((item: string) => {
    return <Link className={props.className} to={`/${item}`} key={item}>{t(capitalize(item))}</Link>
  })

  return (
    <>
      {linksList}
    </>
  )
}
