import React from 'react';

export default class PlusLink extends React.PureComponent {
  render() {
    const { href, right, vertical, children, className, ...rest } = this.props;
    return (
      <a href={href} className={`plus-link ${vertical ? 'plus-link-vertical' : ''} ${right ? 'plus-link-right' : ''} ${className}`} {...rest}>
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18ZM17.3802 10.5517C17.3802 10.2089 17.6581 9.93103 18.0009 9.93103C18.3437 9.93103 18.6216 10.2089 18.6216 10.5517V17.3793H17.3802V10.5517ZM17.3802 18.6207H10.5518C10.209 18.6207 9.93112 18.3428 9.93112 18C9.93112 17.6572 10.209 17.3793 10.5518 17.3793H17.3802V18.6207ZM17.3802 18.6207H18.6216V25.4483C18.6216 25.7911 18.3437 26.069 18.0009 26.069C17.6581 26.069 17.3802 25.7911 17.3802 25.4483V18.6207ZM18.6216 18.6207V17.3793H25.4484C25.7912 17.3793 26.0691 17.6572 26.0691 18C26.0691 18.3428 25.7912 18.6207 25.4484 18.6207H18.6216Z" fill="white"/>
        </svg>
        <span>{children}</span>
      </a>
    );
  }
}

