import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../partials/Icons";
import { useTranslation } from "react-i18next";

const BackgroundImage = (image) => {
  const background = { backgroundImage: `url(${image})` };

  return (
    <React.Fragment>
      <div className="news-item-image" style={background} />
      <div className="news-item-image__hover" style={background} />
    </React.Fragment>
  );
};

const BackgroundImageHero = (image) => {
  const background = { backgroundImage: `url(${image})` };

  return (
    <React.Fragment>
      <div className="news-hero-image" style={background} />
      <div className="news-hero-image__hover" style={background} />
    </React.Fragment>
  );
};

const BackgroundLogo = () => {
  return (
    <div className="news-item-bg-logo">
      <Logo />
    </div>
  );
};

export const NewsList = (props) => {
  const { t } = useTranslation();

  return (
    <Link to={`/news${props.link}`} className="news-item">
      <div className="news-item-image__limiter media-container is-shown">
        <div className="media-container__appear is-white" />
        <div className="media-container__appear is-color" />
        {props.image ? BackgroundImage(props.image) : BackgroundLogo()}
      </div>
      <p className="news-item-name">{props.title}</p>
      <div className="news-item-link-wrap">
        {/* <span className="news-item-link">{t('Read news')}</span> */}
        <time className="news-item-date">{props.date}</time>
      </div>
    </Link>
  );
};

export const NewsHero = (props) => {
  const textClassName = props.image ? "" : "is-dark";

  return (
    <Link
      to={`/news${props.link}`}
      className="news-hero media-container is-shown"
    >
      <div className="media-container__appear is-white" />
      <div className="media-container__appear is-color" />
      {props.image ? BackgroundImageHero(props.image) : BackgroundLogo()}
      <time className="news-hero-date">{props.date}</time>
      <p className={`news-hero-name ${textClassName}`}>{props.title}</p>
    </Link>
  );
};
