import React, {Component} from 'react';
import SplitText from "../../SplitText";
const desktopBreakpoint = 1400;

class CarouselSlide extends Component{
  componentDidMount() {
    const splitText = new SplitText;
    const containerWidth = this.textTag.clientWidth;
    const bias = window.screen.availWidth < desktopBreakpoint ? 100 : 110; // отступ внутри контейнера
    const padding = window.getComputedStyle(this.textTag, null).getPropertyValue('padding-right');

    for (let line of splitText.splitTextByActualLineWidth(this.textTag, this.props.text, containerWidth - ((parseInt(padding)) + bias))) {
      const blockElement = document.createElement('p');
      const lineElement = document.createElement('span');
      blockElement.appendChild(lineElement);
      lineElement.textContent = line;
      this.textTag.appendChild(blockElement);
    }
  }

  getTextRef = (node) => {this.textTag = node};

  render() {
    return (
      <div className={`main-carousel-slide ${this.props.active}`}>
        <div className="main-carousel-slide__title-wrap">
          <span className="main-carousel-slide__title">{this.props.title}</span>
        </div>
        <div className="main-carousel-slide__text" ref={this.getTextRef}></div>
      </div>
    )
  }
}

export default CarouselSlide;
