import React, { Component } from 'react';
import AnchorLink from "react-anchor-link-smooth-scroll";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import Navbar from "../../partials/navbar/Navbar";
import Footer from "../../partials/footer/Footer";
import { Doc } from "../../partials/Icons";
import HighlightAnchor from "../../HighlightAnchor";
import AboutCarousel from "./AboutCarousel";
import { AboutTimeline } from "./AboutTimeline";
import LicenceBlock from "./Licences";

const anchors = new HighlightAnchor();

class ContentAbout extends Component {
  state = {
    colored: false,
    categories: [],
    docs: []
  }

  async componentDidMount() {
    await this.getData();

    anchors.init();
  }

  getData = () => {
    fetch('/api/v1/product_categories', {
      headers: {
        "Locale": window.localStorage.getItem('locale') || "ru"
      }
    })
      .then(result => {
        return result.json()
      })
      .then(res => {
        const categories = res.data
          .map(category => category.attributes)
          .filter(category => category.category_type === "category");

        this.setState({ categories })
      })
      .catch(error => console.error(error));
    fetch('/api/v1/company_documents', {
      headers: {
        "Locale": window.localStorage.getItem('locale') || "ru"
      }
    })
      .then(result => {
        return result.json()
      })
      .then(res => {
        const docs = res.data.map(doc => doc.attributes);

        this.setState({ docs })
      })
      .catch(error => console.error(error));
  }

  changeColorState = (state) => {
    this.setState({ colored: state });
  }

  renderFacts = () => {
    const slicedCategories = this.state.categories.slice(0, 4);

    return slicedCategories.map((category, index) => {
      return <Link
        className="about-text-item"
        to={`/products/${category.slug}`}
        key={`link-${index}`}
      >
        {category.name}
      </Link>
    });
  }

  renderDocs = () => {
    return this.state.docs.map((doc, index) => {
      return <a
        href={doc.file}
        className="about-info-link"
        download
        key={`doc-${index}`}>
        <Doc/>
        <span>{doc.name}</span>
      </a>
    });
  }

  render() {
    const { t } = this.props;
    const coloredClass = this.state.colored ? "navbar-theme-colored" : "";

    return (
      <React.Fragment>
        <Navbar changeColorState={this.changeColorState}/>
        <main>
          <section className="about-wrap">
            <ul className={`breadcrumbs is-absolute ${coloredClass}`}>
              <li><a href="/">{t('Main page')}</a></li>
              <li>{t('About')}</li>
            </ul>
            <div className="about-anchors">



              <nav className={`anchors-list ${coloredClass}`}>
                <AnchorLink href="#anchorAbout" className="anchor-link j-anchor-link is-active">
                  {t('About')}
                </AnchorLink>
                <AnchorLink href="#anchorGallery" className="anchor-link j-anchor-link">
                  {t('Gallery')}
                </AnchorLink>
                <AnchorLink href="#anchorHistory" className="anchor-link j-anchor-link">
                  {t('History')}
                </AnchorLink>
                <AnchorLink href="#anchorLicences" className="anchor-link j-anchor-link">
                  {t('Licenses')}
                </AnchorLink>
                <AnchorLink href="#anchorInfo" className="anchor-link j-anchor-link">
                  {t('Information')}
                </AnchorLink>
              </nav>
            </div>
            <div id="anchorAbout" className="about-container section_theme_padding-top j-anchor-container">
              <h1 className="about-title j-text-appear">
                <span className="text-appear-wrap">
                  <span className="text-appear">
                    {t('PO Eltechnika')}
                  </span>
                </span>
              </h1>
            </div>
          </section>
          <AboutCarousel/>
          <AboutTimeline/>
          <LicenceBlock/>
          <section id="anchorInfo" className="about-info-section j-anchor-container">
            <h2 className="about-info-title">{t('Information')}</h2>
            <div className="about-info-wrap">
              {this.renderDocs()}
            </div>
            <a href="/products" className="go-to-catalog">{t('Our products')}</a>
          </section>
        </main>
        <Footer/>
      </React.Fragment>
    )
  }
}

export default withTranslation()(ContentAbout);
