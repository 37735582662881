import * as React from 'react'
import { Helmet } from 'react-helmet'

const MetaTags = (props) => {
    const { tags } = props
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{tags.title}</title>
            <meta name="description" content={tags.description} />
            <meta name="keywords" content={tags.keywords} />
            <meta property="og:title" content={tags.title} />
            <meta property="og:description" content={tags.description} />
            <meta property="og:site_name" content="elteh.ru" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={tags.path} />
        </Helmet>
    )
}

export default MetaTags
