import api from "./index";

export async function getPostData(slug) {
  try {
    const response = await api.get(`posts?filter[slug]=${slug}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
}
