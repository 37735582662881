import React, {PureComponent} from 'react';


export class Properties extends PureComponent {
  state = {data: []}

  componentDidMount() {
    this.setState({data: this.props})
    // console.log(this.props);

    /*fetch(property.relationships.product_properties.links.related)
      .then(result => result.json())
      .then(res => {
        data = res.data;
      })*/
  }

  renderRow = (row) => {
    console.log(row);

    return <tr>
      <td>1</td>
    </tr>
  }

  render() {
    const {data} = this.state;
    return(
      // {data.map(item)}
      <div>11</div>
    )
  }
}
