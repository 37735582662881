import React from "react";
import { Doc } from "./types";
import SearchResultItem from "./SearchResultItem";

interface Props {
  data: Doc[];
}

const renderItems = (data) =>
  data.map((doc) => <SearchResultItem document={doc} />);

const SearchResult = (props: Props) => {
  const { data } = props;
  return data ? renderItems(data) : <></>;
};

export default SearchResult;
