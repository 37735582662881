import React, { useEffect, useState } from "react";
import { Category } from "../../../types";
import Documents from "./Documents";
import axios from "axios";

import { ChevronBig } from "../../partials/Icons";
import { useTranslation } from "react-i18next";

import { lang } from "../../../variables/common";

interface SubCategoriesProps {
  category: Category;
}

const Products = (props: SubCategoriesProps): JSX.Element => {
  const { category } = props;
  const [data, setData] = useState<any>([]);
  const { t } = useTranslation();

  async function fetchRelationships(category: Category) {
    const path = category.relationships?.products?.links.related || "/";
    const res = await axios.get(path, {
      headers: {
        Locale: lang,
      },
    });
    setData(res.data.data);
  }

  function toggleList(event) {
    event.target.parentElement.classList.toggle("is-active");
  }

  useEffect(() => {
    fetchRelationships(category);
  }, []);

  return (
    <div className="documents-category__products">
      {data.map((product) => {
        const status = !!product && !!product.attributes.name;
        if (!status) return;
        return (
          <nav key={product} className="documents-category__product">
            <span
              className="documents-category__product__header"
              onClick={(event) => toggleList(event)}
            >
              {product.attributes.name}
              <ChevronBig />
            </span>
            <Documents product={product} />
          </nav>
        );
      })}
    </div>
  );
};

export default Products;
