import React, { Component } from "react";
import { TweenLite } from "gsap";
import { LeftControlSquare, RightControlSquare } from "../../partials/Icons";

export default class CatalogVideo extends Component {
  state = {
    sceneActiveIndex: 0,
    scenePoints: [this.props.data],
    prevBtnDisabled: true,
    nextBtnDisabled: false,
  };

  componentDidMount() {
    TweenLite.to(this.videoReversed, 0, { opacity: 0 });
    // this.handleNavigationClick(0);
    document.onkeydown = this.handleKeyPress;
  }

  getVideoRef = (node) => (this.video = node);
  getVideoReversedRef = (node) => (this.videoReversed = node);

  renderDots = (item, index) => {
    const activeClass =
      index === this.state.sceneActiveIndex ? "is-active" : "";

    return (
      <li
        className={`catalog-navigation__item ${activeClass}`}
        onClick={() => this.handleNavigationClick(index)}
        key={`navItem-${index}`}
      ></li>
    );
  };

  handleKeyPress(event) {
    const keyDownEvent = event || window.event;
    const keycode = keyDownEvent.which
      ? keyDownEvent.which
      : keyDownEvent.keyCode;
    const leftValue = 37;
    const rightValue = 39;

    switch (keycode) {
      case leftValue:
        this.handleNavigationClick(this.state.sceneActiveIndex - 1);
        break;
      case rightValue:
        this.handleNavigationClick(this.state.sceneActiveIndex + 1);
        break;
      default:
        break;
    }
  }

  handleNavigationClick(index) {
    if (index < 0 || index >= this.state.scenePoints.length) {
      return;
    }

    const startValue = this.state.scenePoints[index].start;
    const endValue = this.state.scenePoints[index].end;

    if (index < this.state.sceneActiveIndex) {
      TweenLite.to(this.videoReversed, 0.3, { opacity: 1 });
      TweenLite.to(this.video, 0.3, { opacity: 0 });
      this.playVideo(this.videoReversed, startValue, endValue);
    } else {
      TweenLite.to(this.videoReversed, 0.3, { opacity: 0 });
      TweenLite.to(this.video, 0.3, { opacity: 1 });
      this.playVideo(this.video, startValue, endValue);
    }

    this.setState({
      prevBtnDisabled: index === 0,
      nextBtnDisabled: index >= this.state.scenePoints.length - 1,
      sceneActiveIndex: index,
    });
  }

  playVideo(video, currentTime, durationTime) {
    const timeout = (durationTime - currentTime) * 1000;
    this.timer = () =>
      setTimeout(() => {
        video.pause();
      }, timeout);

    clearTimeout(this.timer());
    video.currentTime = currentTime;
    video.play();
    this.timer();
  }

  render() {
    setTimeout(() => {
      console.log(this.state.scenePoints);
    }, 2000);
    return (
      <div className="catalog-screen">
        <video
          className="catalog-player is-forward"
          ref={this.getVideoRef}
          autoPlay
        >
          <source src="/videos/KRU_full_1.mp4" />
        </video>
        <video
          className="catalog-player is-backward"
          ref={this.getVideoReversedRef}
          autoPlay
        >
          <source src="/videos/KRU_FULL_revers.mp4" />
        </video>
        <div className="catalog-controls">
          <button
            className="btn-control is-prev"
            onClick={this.handleNavigationClick.bind(
              this,
              this.state.sceneActiveIndex - 1
            )}
            disabled={this.state.prevBtnDisabled}
          >
            <LeftControlSquare />
          </button>
          <button
            className="btn-control is-next"
            onClick={this.handleNavigationClick.bind(
              this,
              this.state.sceneActiveIndex + 1
            )}
            disabled={this.state.nextBtnDisabled}
          >
            <RightControlSquare />
          </button>
          <ul className="catalog-navigation">
            {this.state.scenePoints.map(this.renderDots)}
          </ul>
        </div>
      </div>
    );
  }
}
