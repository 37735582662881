import React, { Component } from "react";
import { TweenMax } from "gsap";
import { Logo } from "./Icons";

export default class Loader extends Component {
  componentDidMount() {
    TweenMax.to(
      ".j-loader svg",
      0.6,
      {
        y: 0,
        delay: 1,
        ease: Power2.easeInOut,
        onComplete: () => {
          TweenMax.to(
            ".j-loader",
            0.6,
            { xPercent: 100, delay: 0.5, ease: Power3.easeInOut }
          );
        }
      }
    );
  }

  render() {
    return(
      <div className="loader j-loader">
        <div className="loader-logo-wrap">
          <Logo />
        </div>
      </div>
    );
  }
}
