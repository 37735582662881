import React, { Component } from "react";
import {Close} from "../../partials/Icons";

export default class Modal extends Component {
  render() {
    return (
      <div className="reviews-modal-wrapper j-reviews-modal">
        <button className="btn reviews-close-btn" onClick={this.props.onClose}><Close/></button>

        <div className="reviews-modal-content is-centered">
          { this.props.children }
        </div>
      </div>
    )
  }
}

export const Image = (props) => {
  const image = props.img ? props.img : '';

  return(
    <div className="reviews-modal-item">
      <svg className="svg-defs">
        <defs>
          <clipPath id="clipping">
            <rect id="rectangle" className="svg-size" x="0" y="0" />
          </clipPath>
        </defs>
      </svg>
      <svg className="svg-size" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
        <image className="svg-image svg-size" xlinkHref={ image } x="0" y="0"/>
      </svg>
    </div>
  );
}
