import React, { Component } from "react";
import SplitText from "../../SplitText";
import {LeftControlSquare, RightControlSquare} from "../../partials/Icons";

const imagesContext = require.context('../../../../media', true);
const desktopBreakpoint = 1024;

export default class CatalogCarousel extends Component {
  state = {
    images: [],
    info: [],
    active: 0
  }

  componentWillMount() {
    this.getData();
  }

  componentDidMount() {
    if (window.screen.availWidth < desktopBreakpoint) {
      this.moveInfoUnderControls();
    }
  }

  getControlsRef = (node) => {this.controls = node};
  getListRef = (node) => {this.dots = node};
  getTextRef = (node) => {this.text = node};

  getData = () => {
    const images = this.props.slides.reduce((acc, value) => [ ...acc, ...value.images ], []);
    const info = this.props.slides.reduce((acc, value) => [ ...acc, ...value.info ], []);
    this.setState({images, info});
  }

  renderControls = (image, index) => {
    return <li className={index === 0 ? 'is-active' : undefined} key={index}></li>
  }

  moveInfoUnderControls = () => {
    setTimeout(() => {
      const container = document.querySelector('.catalog-carousel-imgs');
      const infoContainer = document.querySelector('.catalog-info-wrap');
      const marginBottom = 20;
      const height = infoContainer.offsetHeight + marginBottom * 2; // добавляем маргин изображения и текста

      container.style.marginBottom = `${height}px`;
      infoContainer.style.marginTop = `-${height + marginBottom * 2}px`;
    }, 200);
  }

  renderSlides() {
    return this.state.images.map((item, index) => {
      const src = imagesContext(item.src);

      return <img className="catalog-carousel-img" src={src} key={index}/>
    });
  }

  renderInfo() {
    return this.state.info.map((item, index) => {
      const activeClass = index === this.state.active ? 'is-active' : '';
      const bias = 100;

      setTimeout(() => {
        const splitText = new SplitText();
        const infoBlock = document.querySelector(`.j-current-text-${index}`);

        for (let line of splitText.splitTextByActualLineWidth(infoBlock, item.text, infoBlock.clientWidth - bias)) {
          const blockElement = document.createElement('p');
          const lineElement = document.createElement('span');
          blockElement.classList.add('catalog-current-paragraph');
          blockElement.appendChild(lineElement);
          lineElement.textContent = line;
          infoBlock.appendChild(blockElement);
        }
      },100);

      return (
        <div className={`catalog-current-info ${activeClass}`} key={index}>
          <p className="catalog-current-title">
            <span>{item.title}</span>
          </p>
          <div className={`catalog-current-text j-current-text-${index}`}></div>
        </div>
      )
    });
  }

  render() {
    return (
      <div className="catalog-current">
        <div className="catalog-info-wrap">
          {this.renderInfo()}
        </div>

        <div className="catalog-carousel">
          <div className="catalog-carousel-imgs">
            {this.renderSlides()}
          </div>
          <div className="catalog-carousel-controls" ref={this.getControlsRef}>
            <button className="btn-control is-prev"><LeftControlSquare /></button>
            <button className="btn-control is-next"><RightControlSquare /></button>
            <ul className="catalog-carousel-dots" ref={this.getListRef}>
              {this.state.images.map(this.renderControls)}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
