import React from "react";
import { useTranslation } from "react-i18next";

const FactTemplate = ({ attributes }) => {
  const { description, name } = attributes;
  return (
    <div className="home-about-fact__wrapper" key={name}>
      <h4 className="home-about-fact__title j-text-appear">{name}</h4>
      <p className="home-about-fact__text">{description}</p>
    </div>
  );
};

export const HomeAboutUs = ({ advantages }) => {
  const { t } = useTranslation();

  return (
    <section className="home-about-section">
      <div className="home-about-content">
        <h2 className="home-about-title j-text-appear">
          <span className="text-appear-wrap">
            <span className="text-appear">{t("Some facts")}</span>
          </span>
        </h2>
        {advantages.map(FactTemplate)}
      </div>
    </section>
  );
};
