import axios from 'axios';
import React, { PureComponent } from "react";
import { TimelineLite, TweenLite } from "gsap";
import { withTranslation } from 'react-i18next';

import { Close } from "../Icons";
import { hideSearchBlock } from './SearchAnimations';
import { debounceTime, duration } from '../../../variables/animations';

const debounce = require("lodash/debounce");

class Search extends PureComponent {
  state = {
    result: []
  };

  constructor() {
    super();
    this.onInputChange = debounce(this.onInputChange, debounceTime);
  }

  onInputChange = async (event) => {
    const inputValue = event.target.value;

    const response = await axios
      .create({
        headers: {
          'Locale': window.localStorage.getItem('locale') || 'ru',
        }
      })
      .post("/api/v1/products/products_search", {
        query: inputValue
      })
      .then(res => {
        return typeof res.data === "string" ? [] : res.data;
      })
      .catch(error => console.error(error));

    this.setState({ result: response });

    const searchItemAnimation = new TimelineLite();

    TweenLite.to(
      ".j-search-block__top",
      duration,
      { ease: Power3.easeInOut, height: "30%" }
    );
    TweenLite.to(
      ".j-search-block__bottom",
      duration,
      { ease: Power3.easeInOut, height: "70%" }
    );
    searchItemAnimation.staggerFromTo(
      ".j-search-item",
      duration,
      { opacity: 0, y: 20 },
      { ease: Power3.easeInOut, opacity: 1, y: 0 },
      0.1
    );
  }

  insertResult = () => {
    return this.state.result.map((item, index) => {
      return <SearchList
        name={item.name}
        category={item.category}
        nameLink={item.nameLink}
        categoryLink={item.categoryLink}
        key={index}/>
    });
  }

  insertResultAmount = () => {
    if (this.state.result.length !== 0) {
      return <span className="search-items-amount">
              Найдено: {this.state.result.length}
            </span>
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="search-top j-search-block__top">
          <div className="search-container">
            <button className="btn" onClick={hideSearchBlock}><Close/></button>
            <div className="search-input-wrap">
              <input
                className="input-search j-search-input"
                onChange={(e) => {
                  e.persist();
                  this.onInputChange(e)
                }}
                defaultValue=""
                type="text"
                placeholder={t('Start search')}
              />
            </div>
          </div>
        </div>

        <div className="search-bottom j-search-block__bottom">
          <div className="search-container search-result-wrap">
            {this.insertResultAmount()}
            <div className="search-result">
              {this.insertResult()}
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export const SearchList = ({ name, nameLink, categoryLink, category }) => {
  return (
    <div className="search-item j-search-item">
      <div className="search-item__row">
        <div className="search-item__lable">Продукт:</div>
        <div className="search-item__value">
          <a href={`products/${categoryLink}/${nameLink}`} className="search-item__link">{name}</a>
        </div>
      </div>
      <div className="search-item__row">
        <div className="search-item__lable">Категория:</div>
        <div className="search-item__value">
          <a href={`products/${categoryLink}`} className="search-item__link">{category}</a>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Search);
