import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TweenLite } from 'gsap';

import api from '../../../api';
import SplitText from '../../SplitText';
import { LeftControl, RightControl } from '../../partials/Icons';
import { tabletWidth } from '../../constants';

const getFacts = () => api.get('/facts');

export const AboutTimeline = () => {
  const [slideList, setSlideList] = useState([]);
  const [active, setActive] = useState(0);
  const dotWidth = window.screen.availWidth >= tabletWidth ? 48 : 30;

  const clickPrevious = () => {
    setActive(active - 1);
    TweenLite.to(
      '.timeline-dots__wrap',
      0.8,
      { x: `+=${dotWidth}` }
    );
  }

  const clickNext = () => {
    setActive(active + 1);
    TweenLite.to(
      '.timeline-dots__wrap',
      0.8,
      { x: `-=${dotWidth}` }
    );
  }

  useEffect(() => {
    getFacts()
      .then(({ data }) => {
        const facts = data.data.map(fact => fact.attributes);
        setSlideList(facts);
      })
      .catch(e => console.error(e));
  }, []);

  return (
    <section id="anchorHistory" className="timeline-section j-anchor-container">
      {!!slideList.length && (
        <div className="timeline-wrap">
          <div className="timeline-btn__wrap is-prev">
            <button
              className="timeline-btn"
              onClick={clickPrevious}
              disabled={active === 0}
            >
              <LeftControl/>
            </button>
          </div>
          <TimelineSlide slide={slideList[active]}/>
          <div className="timeline-btn__wrap is-next">
            <button
              className="timeline-btn"
              onClick={clickNext}
              disabled={active === slideList.length - 1}
            >
              <RightControl/>
            </button>
          </div>
        </div>
      )}
      <TimelineDots slidesAmount={slideList.length} active={active}/>
    </section>
  )
}

const TimelineSlide = ({ slide }) => {
  const splitText = new SplitText;
  const containerWidth = 180;
  const textRef = useRef();

  useEffect(() => {
    const text = textRef.current.innerText;
    textRef.current.innerText = '';

    for (let line of splitText.splitTextByActualLineWidth(textRef.current, text, containerWidth)) {
      const blockElement = document.createElement('p');
      const lineElement = document.createElement('span');
      blockElement.appendChild(lineElement);
      lineElement.textContent = line;
      textRef.current.appendChild(blockElement);
    }

    TweenLite.fromTo(
      ".j-year",
      0.7,
      { yPercent: 100 },
      { yPercent: 0 }
    );
  }, [slide]);

  return (
    <div className="timeline-slide">
      <div className="timeline-year"><i className="j-year">{slide.year}</i></div>
      <div className="timeline-text"
           dangerouslySetInnerHTML={{ __html: slide.description }}
           ref={textRef}
      />
    </div>
  )
}

const TimelineDots = ({ active, slidesAmount }) => {
  const slides = useMemo(() => {
    return [...Array(slidesAmount + 15).keys()]
  }, []);
  const renderDot = (el, index) => {
    const activeClass = index === active + 7 ? 'is-active' : '';
    const remoteness = Math.abs(index - (active + 7));

    return <div className="timeline-dots__cell" key={index}>
      <div
        className={`timeline-dots__dot ${activeClass}`}
        data-index={remoteness}
      />
    </div>
  }

  return (
    <div className="timeline-dots__container">
      <div className="timeline-dots__wrap">
        {slides.map(renderDot)}
      </div>
      <div className="timeline-dots__line"/>
    </div>
  )
}
