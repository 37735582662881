import { TimelineMax, TweenLite } from 'gsap';
import { durationShort, duration } from '../../../variables/animations';

export const showSearchBlock = () => {
  const topAnimation = new TimelineMax();
  const bottomAnimation = new TimelineMax();

  topAnimation.to(
    ".j-search-block__top",
    duration,
    {ease: Power3.easeInOut, height: "50%"}
  );
  bottomAnimation.to(
    ".j-search-block__bottom",
    duration,
    {ease: Power3.easeInOut, height: "50%"}
  );
  TweenLite.to(
    ".j-search-block__top .icon-close",
    0.2,
    {ease: Power3.easeInOut, delay: duration, yPercent: 100}
  );
  TweenLite.to(
    ".j-search-block__top .j-search-input",
    duration,
    {ease: Power3.easeInOut, delay: duration, yPercent: -100}
  );
  TweenLite.to(
    "main",
    duration,
    {ease: Power3.easeInOut, scale: 0.9, opacity: 0}
  );
  document.body.classList.add('body-fixed');
}

export const hideSearchBlock = () => {
  TweenLite.to(
    ".j-search-block__top .icon-close",
    durationShort,
    {ease: Power3.easeInOut, yPercent: 0}
  );
  TweenLite.to(
    ".j-search-block__top .j-search-input",
    duration,
    {ease: Power3.easeInOut, yPercent: 0}
  );
  TweenLite.to(
    "main",
    duration,
    {ease: Power3.easeInOut, delay: durationShort, scale: 1, opacity: 1}
  );
  TweenLite.to(
    ".j-search-block__top",
    duration,
    {ease: Power3.easeInOut, delay: durationShort, height: 0}
  );
  TweenLite.to(
    ".j-search-block__bottom",
    duration,
    {ease: Power3.easeInOut, delay: durationShort, height: 0}
  );

  document.body.classList.remove('body-fixed');
}
