import React, { useEffect, useState } from "react";
import Navbar from "../../partials/navbar/Navbar";
import { useTranslation } from "react-i18next";
import Footer from "../../partials/footer/Footer";
import { Category } from "../../../types";
import CategoryContent from "./CategoryContent";
import api from "../../../api";
import axios from "axios";
import SearchResult from "./SearchResult";
import { Doc } from "./types";

const ContentDocuments = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState<Category[]>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [documentsFound, setDocumentsFound] = useState<Doc[]>();

  const getData = async () => {
    const categories = await api.get(
      "/product_categories?include=attachments,products"
    );
    categories && setCategories(categories.data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  let timer: ReturnType<typeof setTimeout>;

  const onInputChange = async (event) => {
    const inputValue = event.target.value;
    setSearchQuery(inputValue);
  };

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      search(searchQuery);
    }, 2000);
  }, [searchQuery]);

  async function search(query) {
    const response: Doc[] = await axios
      .create({
        headers: {
          Locale: window.localStorage.getItem("locale") || "ru",
        },
      })
      .post("/api/v1/documents/documents_search", {
        query,
      })
      .then((res) => {
        return typeof res.data === "string" ? [] : res.data;
      })
      .catch((error) => console.error(error));

    setDocumentsFound(response);
  }

  return (
    <>
      <Navbar />
      <main className="main__theme-blue">
        <section className="documents-section">
          <ul className="breadcrumbs is-absolute">
            <li>
              <a href="/">{t("Main page")}</a>
            </li>
            <li>{t("Documents")}</li>
          </ul>
          <section className="documents-container">
            <div className="documents-content">
              <h1 className="documents-title j-text-appear">
                <span className="text-appear-wrap">
                  <span className="text-appear">{t("Documents")}</span>
                </span>
              </h1>
              <input
                className="documents-search__input"
                type="text"
                onChange={(e) => onInputChange(e)}
                value={searchQuery}
                placeholder={t("Search")}
              />
              {documentsFound && documentsFound.length > 0 ? (
                <div className="documents-search__result">
                  <SearchResult data={documentsFound} />
                </div>
              ) : (
                <div className="documents-categories">
                  {categories?.map((category) => (
                    <CategoryContent key={category.id} category={category} />
                  ))}
                </div>
              )}
            </div>
          </section>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default ContentDocuments;
