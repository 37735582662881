import React from "react";

export const renderGroupValue = (
  configurator: any,
  index: string,
  placeholder: any
) => {
  const i = index === "subGroup" ? "subGroup" : `group${index}`;

  const steps = configurator.json?.included.filter(
    (item: any) => item.type === "configurator_steps"
  );

  const group = configurator.groups[i];
  const code = configurator.codes[i];

  if (Array.isArray(group)) {
    return <span className="configurator-serial">{code || placeholder}</span>;
  }

  if (configurator.stepIndex) {
    // const step = steps[configurator.stepIndex - 1]

    // if (step?.attributes?.mask_number > 0) {
    return (
      <span className="configurator-serial">
        {group.name || <i>{placeholder}</i>}
      </span>
    );
    // }
  }
};

export const renderGroupCode = (configurator: any, index: string) => {
  const i = index === "subGroup" ? "subGroup" : `group${index}`;
  const code = configurator.codes[i];

  const steps = configurator.json?.included.filter(
    (item: any) => item.type === "configurator_steps"
  );

  if (configurator.stepIndex) {
    // const step = steps[configurator.stepIndex - 1]

    // if (step?.attributes?.mask_number > 0) {
    if (code) {
      return <span className="configurator-serial">{code}</span>;
    }
  }
};

export const renderGroups = (group: any) => {
  if (Array.isArray(group)) {
    return group.map((item) => (
      <li key={`result-group-${item.id}`}>{item.attributes.value}</li>
    ));
  }

  return <li>{group.name}</li>;
};

export const renderGroupsCode = (code: string) => {
  if (code.length === 0)  {
    return '';
  } else {
    return <li>{code}</li>;
  }

};

export const getGroupValue = (group: any) => {
  if (Array.isArray(group)) {
    return group.map((item) => item.attributes.value);
  }

  return group.name;
};

export const getGroupCode = (code: string) => {
  if (code.length === 0)  {
    return '';
  } else {
    return code;
  }
};

export const getSerial = ({ groups, codes }: any) => {
  const modelType = getGroupValue(groups.group0)
  let serial = modelType;

  if (isSLmodel(modelType)) {
    serial += `-${codes.group3}`
    serial += `.${codes.group2}`
    serial += `.${codes.group7}`
    serial += ` ${codes.group1}`
  } else {
    serial += `-${codes.group3}`
    serial += `-${codes.group0}`
    serial += `-${codes.group2}`

    if (codes.group7) {
      serial += `-${codes.group7}`
    }

    serial += `-${codes.group1}`
    serial += `-${codes.group5}`
    serial += `.${codes.group6}`
  }

  return serial;
}

export const resetCodes = ({ stepValues, codes }: any, fromIndex: number) => {
  const stepValuesExcluded = stepValues.filter(item => item.index >= fromIndex)

  const codeKeys = stepValuesExcluded.map((stepValue: any) => {
    return Object.keys(codes).find((key) => key === `group${stepValue.code_index}`);
  })

  codeKeys.forEach((key: string) => {
    codes[key] = "";
  })

  return codes;
}

export const getBaseSerial = ({ groups, codes }: any) => {
  let baseCodes: any[] = [];

  if (isSLmodel(getGroupValue(groups.group0))) {
    baseCodes.push(
      getGroupValue(groups.group0),
      codes.group3
    )
  } else {
    baseCodes.push(
      getGroupValue(groups.group0),
      codes.group3,
      codes.group0,
      codes.group2,
      codes.group7,
      codes.group1
    )
  }

  const baseSerial = baseCodes.filter((item) => item).join('-')

  return baseSerial;
}

export const isSLmodel = (modelType: string) => {
  const SLmodelName = /^SL\d{2}$/i; // regex is SL model name

  let matchedSLmodelName;

  if (Array.isArray(modelType)) {
    matchedSLmodelName = modelType.find(item => item.match(SLmodelName));
  } else {
    matchedSLmodelName = modelType.match(SLmodelName);
  }

  return matchedSLmodelName ? true : false;
}
