import axios, { AxiosRequestConfig } from "axios";

import { lang } from "../variables/common";

const api = axios.create({
  baseURL: "/api/v1",
  headers: {
    locale: lang,
  },
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  config.params = { ...config.params, locale: lang };
  return config;
});

export default api;
