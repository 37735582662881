import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getProductsRelated } from "../../../api/catalog";

const SubCategories = ({ subcategory, category }) => {
  const [data, setData] = useState([]);

  async function getData() {
    const url = `${subcategory.relationships.products.links.related}`;
    const res = await getProductsRelated(url);
    setData(res);
  }

  useEffect(() => {
    getData();
  }, []);
  if (!data.length) return null;

  const renderProducts = () => {
    return data.map((product: any) => {
      return (
        <li key={`product-list-item-${product.id}`}>
          <Link
            to={`/products/${category}/${product.attributes.slug}`}
            className="category-link"
          >
            {product.attributes.name}
          </Link>
        </li>
      );
    });
  };

  return <>{renderProducts()}</>;
};

export default SubCategories;
