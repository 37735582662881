import React, { Component } from 'react';
import { TweenLite } from "gsap";

import Footer from "../../partials/footer/Footer";
import Accordion from "../../Accordion";
import Navbar from "../../partials/navbar/Navbar";
import api from "../../../api";
import Modal, { Image, List } from "./Modal";
import { withTranslation } from 'react-i18next';


const getReviews = () => api.get('/reviews?include=product_category,our_client')

class ContentReviews extends Component {
  state = {
    selectedIndex: -1,
    modalOpen: this.props.opened,
    list: [],
    img: "",
    activeImageIndex: "",
    categories: [],
    clients: [],
    reviews: []
  }

  componentDidMount() {
    this.getReviews();

    const timelineImg = new TimelineMax();

    this.imgAnimation = timelineImg.from(
      "#rectangle",
      0.6,
      {
        delay: 0.7,
        ease: Power3.easeInOut,
        scaleX: 0,
        transformOrigin: "100% 0"
      }
    ).reversed(true);
  }

  async getReviews() {
    const response = await getReviews()

    this.normalizeCategory(response.data)
  }

  normalizeCategory = (data) => {
    let reviews = [];
    let categories = [];
    let clients = [];

    data.included.map(item => {
      if (item.type === "our_clients") {
        const clientData = {
          id: item.id,
          name: item.attributes.name
        };

        clients.push(clientData);
      }
    });

    data.data.map(review => {
      const clientName = clients.find(el => {
        return el.id === String(review.attributes.our_client_id)
      });
      const reviewData = {
        id: String(review.id),
        client: clientName.name,
        categoryId: String(review.attributes.product_category_id),
        image: review.attributes.image
      };

      reviews.push(reviewData);
    });

    data.included.map(item => {
      if (item.type === "product_categories") {
        const categoryData = {
          id: item.id,
          title: item.attributes.name,
          images: reviews.filter(review => review.categoryId === item.id)
        };

        categories.push(categoryData);
      }
    });

    this.setState({ reviews, categories, clients });
  }

  findData = (payload, id, type) => {
    return payload.find(el => {
      return el.type = type && el.id == id
    })
  }

  setSelectedAccordion = (state) => {
    this.setState({ selectedIndex: state })
  }

  openModal = (list, key) => {
    const modalState = this.state.modalOpen;

    this.setState({
      modalOpen: !modalState,
      list: list,
      img: list[key].image,
      activeImageIndex: key
    });

    this.imgAnimation.reversed(!this.imgAnimation.reversed());

    TweenLite.to(
      ".j-reviews-modal",
      0.8,
      { xPercent: 100, ease: Power3.easeInOut }
    );
    TweenLite.fromTo(
      ".reviews-close-btn .icon-close",
      0.2,
      { yPercent: -100 },
      { yPercent: 0, delay: 0.8 }
    );

    setTimeout(() => {
      const timelineLinks = new TimelineMax();

      this.linksAnimation = timelineLinks.staggerFromTo(
        ".j-review-link",
        0.5,
        { y: 20 },
        { y: 0, ease: Power3.easeInOut, delay: 0.9 }
      );
    }, 200);
  }

  closeModal = (list, key) => {
    const modalState = this.state.modalOpen;

    if (list[key]) {
      this.setState({
        modalOpen: !modalState,
        list: list,
        img: list[key].src,
        activeImageIndex: key
      });
    } else {
      this.setState({ modalOpen: !modalState });
    }

    const timelineLinks = new TimelineMax();

    this.linksAnimation = timelineLinks.staggerFromTo(
      ".j-review-link",
      0.5,
      { y: 20 },
      { y: 0, ease: Power3.easeInOut, delay: 0.9 }
    );

    TweenLite.to(
      ".j-reviews-modal",
      0.8,
      { delay: 0.7, xPercent: 0, ease: Power3.easeInOut }
    );
    TweenLite.to(
      ".reviews-close-btn .icon-close",
      0.2,
      { yPercent: -100, delay: 0.4 }
    );
    this.imgAnimation.reversed(!this.imgAnimation.reversed());
  }

  setActiveImage = (prop) => {
    this.setState({ activeImageIndex: prop.activeIndex, img: this.state.list[prop.activeIndex].image });
  }

  renderClient = (item, catIndex, clientIndex) => {
    return (
      <button
        className="btn show-popup-btn"
        onClick={this.openModal.bind(this, this.state.categories[catIndex].images, clientIndex)}
        key={`client-${clientIndex}`}>
        {item.client}
      </button>
    )
  }

  renderCategory = () => {
    if (this.state.categories.length === 0) return;

    return this.state.categories.map((category, catIndex) => {
      return <div
        data-header={category.title}
        className="accordion-content"
        key={`category-${catIndex}`}>
        {category.images.map((image, clientIndex) => {
          return this.renderClient(image, catIndex, clientIndex);
        })}
      </div>
    })
  }

  render() {
    const { t } = this.props;
    const { modalOpen, img, activeImageIndex } = this.state;

    return (
      <React.Fragment>
        <Navbar/>
        <main className="main__theme-blue">
          <section className="reviews-section">
            <ul className="breadcrumbs is-absolute">
              <li><a href="/">{t('Main page')}</a></li>
              <li>{t('Reviews')}</li>
            </ul>

            <section className="reviews-navigation">
              {/*<a href="#">Референс-лист</a>*/}
            </section>

            <section className="reviews-container section_theme_padding-top">
              <h1 className="reviews-title j-text-appear">
                <span className="text-appear-wrap">
                  <span className="text-appear">{t('Customer Reviews')}</span>
                </span>
              </h1>

              <Accordion
                className="accordion accordion_theme_reviews"
                selectedIndex={this.state.selectedIndex}
                setSelectedAccordion={this.setSelectedAccordion}>
                {this.renderCategory()}
              </Accordion>
            </section>
          </section>
          <Modal show={modalOpen}
                 onClose={this.closeModal.bind(this)}>
            <List items={this.state.list}
                  activeItem={activeImageIndex}
                  activeProp={(activeIndex) => this.setActiveImage({ activeIndex })}/>
            <Image img={img}/>
          </Modal>
        </main>
        <Footer/>
      </React.Fragment>
    )
  }
}

export default withTranslation()(ContentReviews);
