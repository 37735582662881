import React from "react";
import { useTranslation } from "react-i18next";

import { Loupe } from "../../Icons";
import { showSearchBlock } from "../../search/SearchAnimations";

export const FooterSearchBtn = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-search-wrap">
      {/* <Loupe/> */}
      <span className="footer-search-text" onClick={showSearchBlock}>
        {t("Search")}
      </span>
    </div>
  );
};
