import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "../Icons";
import Menu from "./partials/Menu";
import Socials from "./partials/Socials";
import { FooterSearchBtn } from "./partials/FooterSearchBtn";
import Lang from "./partials/Lang";
import { Settings } from "../../../types";

export default function Footer() {
  const { t } = useTranslation();
  const [settings, setSettings] = useState<Settings>({});

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetch("/api/v1/settings", {
      headers: {
        Locale: window.localStorage.getItem("locale") || "ru",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const settings = data.data.map((settings) => settings.attributes)[0];
        setSettings(settings);
      })
      .catch((error) => console.error(error));
  };

  return (
    <footer className="footer">
      <a href="/" className="footer-logo">
        <Logo />
      </a>
      <Menu />
      <Socials settings={settings} />
      <a href="mailto:info@elteh.ru" className="footer-link footer-email">
        info@elteh.ru
      </a>
      <a href="tel:+78123299797" className="footer-link footer-phone">
        +7 (812) 329 97 97
      </a>
      <p className="footer-text footer-address">{t("Address")}</p>
      <FooterSearchBtn />
      <Lang />
      <p className="footer-text footer-copyrights">
        (c) 2001-{new Date().getFullYear()}, {t("Rights")}
      </p>
      <a
        href={settings.data_processing_doc}
        className="footer-link footer-policy"
        target="_blank"
      >
        {t("Privacy")}
      </a>
      <a
        href="http://molinos.ru"
        className="footer-text footer-company footer-link"
        target="_blank"
      >
        {t("In Molinos")}
      </a>
    </footer>
  );
}
