export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const CLEAR_CART = 'CLEAR_CART';


export const changeQuantity = (order, amount) => {
  return {
    type: CHANGE_QUANTITY,
    payload: {order, amount}
  }
}

export const removeCartItem = (item) => {
  return {
    type: REMOVE_CART_ITEM,
    payload: item,
  }
}

export const clearCart = () => {
  return {
    type: CLEAR_CART,
    payload: []
  }
}
